import axios from 'axios'
import { getToken } from '@/auth'
import version from '@/version'

axios.defaults.withCredentials = true

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 360000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.VUE_APP_API_KEY,
    'version': version
  }
})

// request interceptor
http.interceptors.request.use(
  async config => {
    const token = await getToken()
    if (token) config.headers['Authorization'] = token
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
http.interceptors.response.use(
  response => {
    if (response.status === 401) {
      return Promise.reject(new Error(response.message || 'Error'))
    }
    return response
  },
  error => {
    return Promise.reject(error.response)
  }
)

export { http }
