/**
 * replace all strings
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 * @returns {string}
 */
export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace)
}

/**
 * set layout details
 * @param {object} config
 */
export function setLayoutPortal(config) {
  document.documentElement.style.setProperty('--background-color', config['background-color'])
  document.documentElement.style.setProperty('--background-button', config['background-button'])
  document.documentElement.style.setProperty('--text-primary-color', config['text-primary-color'])
  document.documentElement.style.setProperty('--text-secondary-color', config['text-secondary-color'])

  // create a link to a favicon
  var link = document.createElement('link')
  link.rel = 'shortcut icon'
  link.type = 'image/x-icon'
  link.href = config.logo

  // get link
  var favicon = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]')

  // delete favicon
  if (favicon) {
    document.head.removeChild(favicon)
  }

  // add new favicon
  document.head.appendChild(link)

  // update
  document.title = `${document.title} | ${config.name}`
}

/**
 * delete special characters
 * @param {object} config
 */
export function removeSpecialCharacters(str) {
  return str.replace(/[^\w\s]/gi, '')
}

/**
 * get router by step
 * @param {object} config
 */
export function getRouterToStep(step) {
  const routers = {
    selfie: 'selfie',
    documents: 'documents',
    resume: 'resume'
  }

  if (step in routers) {
    return routers[step]
  }

  return null
}
