import * as MutationTypes from '../mutation-types'
import { errorResponseHelper } from '@/helpers'
import { underLoadingFunction } from '../helpers/under-loading'

import AuthService from '@/services/auth.service'

import {
  clearToken,
  getAccessData
} from '@/auth'

const client = {
  'background-color': '',
  'background-button': '',
  'text-primary-color': '',
  'text-secondary-color': '',
  'logo': '',
  'name': '',
  'template': '',
  'transaction-permition': false
}

const state = {
  device: 'desktop',
  isLoading: false,
  tituloLoading: '',
  header: {
    title: { title: '', icon: '', align: '' },
    right: true,
    hidden: true
  },
  client: {
    'background-color': '',
    'background-button': '',
    'text-primary-color': '',
    'text-secondary-color': '',
    'logo': '',
    'name': '',
    'template': '',
    'transaction-permition': false
  },
  flow: []
}

const mutations = {
  [MutationTypes.TOGGLE_DEVICE](mutationState, device) {
    mutationState.device = device
  },
  [MutationTypes.SET_LOADING](mutationState, isLoading) {
    mutationState.isLoading = isLoading || false
  },
  [MutationTypes.SET_TEXT_LOADING](mutationState, text) {
    mutationState.tituloLoading = text
  },
  [MutationTypes.SET_CLIENT]: (mutationState, client) => {
    mutationState.client = client
  },
  [MutationTypes.UPDATE_HEADER]: (mutationState, header) => {
    mutationState.header = header
  },
  [MutationTypes.SET_FLOW]: (mutationState, flow) => {
    mutationState.flow = flow
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit(MutationTypes.TOGGLE_SIDEBAR)
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit(MutationTypes.CLOSE_SIDEBAR, withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit(MutationTypes.TOGGLE_DEVICE, device)
  },
  setSize({ commit }, size) {
    commit(MutationTypes.SET_SIZE, size)
  },
  setLoading({ commit }, status) {
    commit(MutationTypes.SET_LOADING, status)
  },
  setTextLoading({ commit }, text) {
    commit(MutationTypes.SET_TEXT_LOADING, text)
  },
  updateHeader({ commit }, header) {
    commit(MutationTypes.UPDATE_HEADER, header)
  },
  setClient({ commit }, data) {
    commit(MutationTypes.SET_CLIENT, data)
  },
  setFlowTransaction({ commit }, data) {
    commit(MutationTypes.SET_FLOW, data)
  },
  async resetProcessUser({ commit }, data) {
    commit(MutationTypes.SET_CLIENT, client)
    const keyAccess = await getAccessData()
    clearToken()
    return keyAccess
  },
  validStepTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await AuthService.validStepTransaction(payload)
      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  })
}

export default {
  state,
  mutations,
  actions
}
