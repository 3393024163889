import '@/assets/vendor/nucleo/css/nucleo.css'
import '@/assets/vendor/font-awesome/css/font-awesome.css'
import '@/assets/scss/global.scss'

import globalComponents from './globalComponents'
import globalDirectives from './globalDirectives'
import VueLazyload from 'vue-lazyload'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueI18n from 'vue-i18n'
import Buefy from 'buefy'

import 'buefy/dist/buefy.css'

export default {
  install(Vue) {
    Vue.use(globalComponents)
    Vue.use(globalDirectives)
    Vue.use(VueLazyload)
    Vue.use(Vuelidate)
    Vue.use(VueTheMask)
    Vue.use(VueI18n)
    Vue.use(Buefy)
  }
}
