import { configurationComponentFace } from '../config/configFace'
import store from '@/store'
import router from '@/router'
import { ToastProgrammatic as Toast } from 'buefy'

export class EnrollmentProcessor {
  constructor(sessionToken, client) {
    this.success = false
    this.latestSessionResult = null
    this.cancelledDueToNetworkError = false
    this.latestNetworkRequest = new XMLHttpRequest()
    this.nextStep = ''
    this.client = client

    // eslint-disable-next-line no-undef
    const customConfig = configurationComponentFace(FaceTecSDK, client.logo)
    // eslint-disable-next-line no-undef
    FaceTecSDK.setCustomization(customConfig)

    // eslint-disable-next-line no-undef
    new FaceTecSDK.FaceTecSession(this, sessionToken)
  }

  async processSessionResultWhileFaceTecSDKWaits(sessionResult, faceScanResultCallback) {
    this.latestSessionResult = sessionResult

    // eslint-disable-next-line no-undef
    if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
      this.latestNetworkRequest.abort()
      faceScanResultCallback.cancel()
      return
    }

    const payload = {
      faceScan: sessionResult.faceScan,
      auditTrailImage: sessionResult.auditTrail[0],
      lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
      sessionId: sessionResult.sessionId
    }

    try {
      // eslint-disable-next-line no-undef
      const dataValidationFace = await store.dispatch('validSelfieFaceTransaction', { payload, agent: FaceTecSDK.createFaceTecAPIUserAgentString(sessionResult.sessionId) })
      // return in case of an error
      if (!dataValidationFace.success) {
        console.log('error...')
        if (dataValidationFace.error) {
          this.cancelDueToNetworkError('Falha na validação', faceScanResultCallback)
        } else {
          this.cancelDueToNetworkError('Unexpected API response, cancelling out.', faceScanResultCallback)
        }
      }

      if (dataValidationFace.success === true && dataValidationFace.retry === false) {
        this.nextStep = dataValidationFace.nextStep
        // eslint-disable-next-line no-undef
        FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage('Selfie validada com sucesso!')
        faceScanResultCallback.proceedToNextStep(dataValidationFace.scanResult)
      }
    } catch (error) {
      console.error('ERROR: ', error)
      this.cancelDueToNetworkError('Unexpected API response, cancelling out.', faceScanResultCallback)
    }
  }

  onFaceTecSDKCompletelyDone() {
    this.success = this.latestSessionResult ? this.latestSessionResult.isCompletelyDone : false

    if (this.success) {
      console.log('Liveness-proven Face Verified')

      Toast.open({
        duration: 3000,
        message: `Selfie validada com sucesso!`,
        position: 'is-top',
        type: 'is-success'
      })

      router.push({
        name: this.nextStep
      })
    } else {
      Toast.open({
        duration: 3000,
        message: `Ocorreu um erro ao validar sua sessão, por favor tente novamente mais tarde.`,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  }

  cancelDueToNetworkError(networkErrorMessage = 'error_default', faceScanResultCallback) {
    if (this.cancelledDueToNetworkError === false) {
      console.log(networkErrorMessage)
      this.cancelledDueToNetworkError = true
      faceScanResultCallback.cancel()
    }
  }

  isSuccess() {
    return this.success
  }
}
