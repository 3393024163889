import BaseService from '@/config/base-service'

class CepHiveService extends BaseService {
  constructor() {
    super(`hive-liveness-portal`)
  }

  getCEP(cep) {
    const params = {
      zipCode: cep
    }
    return this.get('common/consult-zipcode', { params })
  }
}

export default new CepHiveService()
