var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-documents" }, [
    _c("div", { staticClass: "position-relative" }, [
      !_vm.captureProcess
        ? _c("section", { staticClass: "section-description-capture" }, [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "row row-grid justify-content-center" },
                [
                  _vm.validInit
                    ? _c("div", { staticClass: "col-lg-12 text-center" }, [
                        _vm._m(0),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-space-between align-center",
                          },
                          [
                            _c("div", { staticClass: "flex-item item" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: "img/icons/common/id-card.png",
                                    expression:
                                      "'img/icons/common/id-card.png'",
                                  },
                                ],
                                staticClass: "card-img",
                              }),
                            ]),
                          ]
                        ),
                        _vm.validInit
                          ? _c("div", [
                              _c("p", { staticClass: "lead" }, [
                                _vm._v(
                                  "\n                Por favor, tenha à mão seu documento de identificação, como RG, CNH ou qualquer outro documento oficial com foto.\n              "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "btn-wrapper" },
                                [
                                  _c(
                                    "base-button",
                                    {
                                      staticClass:
                                        "btn btn-primary mb-3 mb-sm-0",
                                      attrs: { icon: "fa fa-camera" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.captureDocuments()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Validar documento\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _c("slide-y-up-transition", [
                        _c(
                          "div",
                          {
                            staticClass: "col-lg-12 text-center alert-message",
                          },
                          [
                            _c("h1", { staticClass: "display-3" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.alert.title) +
                                  "\n              "
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-column justify-space-between align-center",
                              },
                              [
                                _c("div", { staticClass: "flex-item item" }, [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "lazy",
                                        rawName: "v-lazy",
                                        value: `img/icons/common/${_vm.alert.img}.png`,
                                        expression:
                                          "`img/icons/common/${alert.img}.png`",
                                      },
                                    ],
                                    staticClass: "card-img",
                                  }),
                                ]),
                              ]
                            ),
                            _c("p", [_vm._v(_vm._s(_vm.alert.message))]),
                            _vm.alert.textButton
                              ? _c(
                                  "base-button",
                                  {
                                    staticClass: "btn btn-primary mb-3 mb-sm-0",
                                    on: {
                                      click: function ($event) {
                                        return _vm.alert.action()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.alert.textButton) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.captureProcess,
              expression: "captureProcess",
            },
          ],
          staticClass: "section-capture-document",
        },
        [
          _c("div", { attrs: { id: "documento" } }),
          _c("img", { attrs: { id: "imgA" } }),
          _c("img", { attrs: { id: "imgB" } }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-3" }, [
      _vm._v("Vamos validar"),
      _c("br"),
      _vm._v("sua identidade"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }