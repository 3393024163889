/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}/hive-liveness-portal/service-worker.js`, {
    ready() {
      console.log(
        'O aplicativo está sendo veiculado no cache por um service worker.\n' +
        'Para mais detalhes, visite https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('O trabalhador de serviço foi registrado.')
    },
    cached() {
      console.log('O conteúdo foi armazenado em cache para uso offline.')
    },
    updatefound() {
      console.log('Novo conteúdo está sendo baixado.')
    },
    updated() {
      console.log('Novo conteúdo está disponível, por favor, atualize.')
    },
    offline() {
      console.log('Nenhuma conexão com a Internet encontrada! O aplicativo está sendo executado no modo offline.')
    },
    error(error) {
      console.error('Erro durante o registro do service worker:', error)
    }
  })
}
