<template>
  <div class="page-documents">
    <div
      id="containerDocumentsOutros"
      class="containerDocumentsOutros"
    >
      <div class="">
        <div
          class="centralizado alignCenter"
        >
          <h2 class="display-3">Adicionar Outros Documentos</h2>
          <p class="lead">Para adicionar outros documentos, basta arrastar e soltar os arquivos ou clicar para selecionar.</p>
          <div>
            <section
              class="uploadDocWeb"
            >
              <b-field
                class="file is-primary"
                :class="{'has-name': !!file}"
              />
              <b-field>
                <b-upload
                  v-model="dropFiles"
                  multiple
                  drag-drop
                >
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon
                          icon="upload"
                          size="is-large"
                        />
                      </p>
                      <p>Arraste ou clique para fazer o upload!</p>
                    </div>
                  </section>
                </b-upload>
              </b-field>

              <div class="tags">
                <span
                  v-for="(item, index) in dropFiles"
                  :key="index"
                  class="tag colorTag"
                >
                  {{ item.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  />
                </span>
              </div>
            </section>
          </div>
          <div class="containerButton">
            <div class="d-grid gap-2 col-6">
              <button
                class="btn btn-primary "
                type="button"
                @click="validTransaction()"
              >
                Continuar validação
              </button>
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OthersDocuments',
  components: {
  },
  data() {
    return {
      showContentUpload: false,
      isComponentModalActive: false,
      file: null,
      dropFiles: [],
      files: []
    }
  },
  computed: {
    ...mapGetters({ })
  },
  created() {
    this.validStep()
  },
  methods: {
    ...mapActions([
      'validStepTransaction',
      'resetProcessUser',
      'validOthersDocumentsTransaction'
    ]),
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1)
    },
    async validStep() {
      const { success, data } = await this.validStepTransaction({ step: 'documents' })
      if (success) {
        const { data: dataInit } = data

        this.licenceKey = dataInit.key
        this.validInit = true
      } else {
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Opss',
          message: this.$t('errors.error_default_restart_transaction'),
          action: this.restartProcess,
          textButton: 'Reiniciar o processo',
          img: 'fail'
        }
      }
    },
    async validTransaction() {
      for (let i = 0; i < this.dropFiles.length; i++) {
        this.convertToBase64(this.dropFiles[i])
      }

      // const data = await this.validOthersDocumentsTransaction({ files: this.files })

      // if (data.success) {
      //   const { data: dataValid } = data.data

      //   this.setFlowTransaction(dataValid.flow)

      //   const nextStep = dataValid.nextStep
      //   if (nextStep) {
      //     this.$router.push({
      //       name: nextStep
      //     })
      //   }
      // } else {
      //   const erroData = data.error.data
      //   const { message: messageKey } = erroData

      //   this.alert = {
      //     show: true,
      //     type: 'danger',
      //     message: this.$t(`errors.${messageKey}`) || 'Não foi possível validar sua solicitação'
      //   }
      // }
    },
    convertToBase64(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.files.push({
          name: file.name,
          base64: reader.result
        })
      }
      reader.onerror = (error) => {
        console.error('Error: ', error)
      }
    },
    async restartProcess() {
      const link = await this.resetProcessUser()
      this.$router.push({
        path: '/',
        query: {
          y: link
        }
      })
    }
  }
}
</script>
<style>
.uploadDocWeb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.containerButton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .containerButton {
    justify-content: flex-start;
  }
}
</style>
