import BaseService from '@/config/base-service'

class AuthService extends BaseService {
  constructor() {
    super(`hive-liveness-portal`)
  }

  validInitTransaction(code, params) {
    return this.get(`portal/valid/${code}`, { params })
  }

  validStepTransaction(payload) {
    return this.post(payload, `portal/valid/step`)
  }
}

export default new AuthService()
