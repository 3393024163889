var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "main",
      [
        _c(
          "fade-transition",
          { attrs: { origin: "center", mode: "out-in", duration: 250 } },
          [_c("router-view")],
          1
        ),
        _c("b-loading", {
          attrs: {
            "is-full-page": true,
            active: _vm.isLoading,
            "can-cancel": false,
          },
          on: {
            "update:active": function ($event) {
              _vm.isLoading = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }