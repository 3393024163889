import {
  replaceAll
} from './index'

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$', 'i')
  return !!pattern.test(str)
}

/**
 * validate cpf
 * @param {string} cpf
 * @returns {boolean}
 */
export function validateCPF(str) {
  const cpf = str && str.toString()
  if (!cpf || cpf.length < 11) return false
  let strCPF = replaceAll(cpf, '.', '')
  strCPF = replaceAll(strCPF, '-', '')
  let Soma = 0
  let Resto
  if (
    (strCPF === '00000000000') ||
    (strCPF === '11111111111') ||
    (strCPF === '22222222222') ||
    (strCPF === '33333333333') ||
    (strCPF === '44444444444') ||
    (strCPF === '55555555555') ||
    (strCPF === '66666666666') ||
    (strCPF === '77777777777') ||
    (strCPF === '88888888888') ||
    (strCPF === '99999999999')
  ) {
    return false
  }
  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11
  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false
  Soma = 0
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  Resto = (Soma * 10) % 11
  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false
  return true
}

export function validateEmail(email) {
  const regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
  return regex.test(email)
}

/**
 * verify device is mobile
 * @param {string} cpf
 * @returns {boolean}
 */
export function checkDeviceIsMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true
  }
  return false
}
