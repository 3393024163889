const configurationComponentFace = (FaceTecSDK, logoURI) => {
  var sdkImageDirectory = '@/assets/vendor/sdk-face/images'

  // For Color Customization
  var outerBackgroundColor = '#FFFFFF'
  var frameColor = '#ffffff'
  var borderColor = '#fcfc30'
  var ovalColor = '#fcfc30'
  var dualSpinnerColor = '#fcfc30'
  var textColor = '#535dff'
  var buttonAndFeedbackBarColor = '#4864fa'
  var buttonAndFeedbackBarTextColor = '#fcfc30'
  var buttonColorHighlight = '#0922ae'
  var buttonColorDisabled = '#ebf2ff'

  // For Frame Corner Radius Customization
  const frameCornerRadius = '20px'

  // For Cancel Button Customization
  var cancelButtonImage = sdkImageDirectory + 'FaceTec_cancel.png'
  var cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopRight

  // For image Customization
  var securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec_ZoOm

  // Set a default customization
  var defaultCustomization = new FaceTecSDK.FaceTecCustomization()

  // Set Frame Customization
  defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius
  defaultCustomization.frameCustomization.backgroundColor = frameColor
  defaultCustomization.frameCustomization.borderColor = borderColor

  // Set Overlay Customization
  defaultCustomization.overlayCustomization.brandingImage = logoURI
  defaultCustomization.overlayCustomization.backgroundColor = outerBackgroundColor

  // Set Guidance Customization
  defaultCustomization.guidanceCustomization.backgroundColors = frameColor
  defaultCustomization.guidanceCustomization.foregroundColor = textColor
  defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor
  defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = buttonColorDisabled
  defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = buttonColorHighlight
  defaultCustomization.guidanceCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor
  defaultCustomization.guidanceCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor
  defaultCustomization.guidanceCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor
  defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor
  defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor

  // Set Oval Customization
  defaultCustomization.ovalCustomization.strokeColor = ovalColor
  defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor
  defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor

  // Set Feedback Customization
  defaultCustomization.feedbackCustomization.backgroundColor = buttonAndFeedbackBarColor
  defaultCustomization.feedbackCustomization.textColor = buttonAndFeedbackBarTextColor

  // Set Cancel Customization
  defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage
  defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation

  // Set Security Watermark Customization
  defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage)

  // Set Result Screen Customization
  defaultCustomization.resultScreenCustomization.backgroundColors = frameColor
  defaultCustomization.resultScreenCustomization.foregroundColor = textColor
  defaultCustomization.resultScreenCustomization.activityIndicatorColor = buttonAndFeedbackBarColor
  defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = buttonAndFeedbackBarColor
  defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = buttonAndFeedbackBarTextColor
  defaultCustomization.resultScreenCustomization.uploadProgressFillColor = buttonAndFeedbackBarColor

  // Set ID Scan Customization
  defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor
  defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor
  defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor
  defaultCustomization.idScanCustomization.reviewScreenForegroundColor = buttonAndFeedbackBarTextColor
  defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = buttonAndFeedbackBarColor
  defaultCustomization.idScanCustomization.captureScreenForegroundColor = buttonAndFeedbackBarTextColor
  defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = buttonAndFeedbackBarColor
  defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor
  defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = buttonColorDisabled
  defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = buttonColorHighlight
  defaultCustomization.idScanCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor
  defaultCustomization.idScanCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor
  defaultCustomization.idScanCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor
  defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor
  defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor

  // Set Initial Loading Customization
  defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = buttonAndFeedbackBarTextColor
  defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = buttonAndFeedbackBarColor

  return defaultCustomization
}
export { configurationComponentFace }
