export const TRANSLATE_SELFIE = {
  FEEDBACK_RETRY: 'Algo está estranho, mova-se um pouco para mudar a iluminação',
  FEEDBACK_RETRY_INSECURE: 'Alimentação de câmera insegura',
  FEEDBACK_RETRY_QUALITY: 'A selfie não ficou com uma boa qualidade, tente capturar novamente!',
  FEEDBACK_ENROLLED: 'Registrado com sucesso',
  FEEDBACK_CAPTURED: 'Capturado com sucesso',
  FEEDBACK_NOT_ENROLLED: 'Usuário não inscrito',
  FEEDBACK_VERIFY_COMPLETED: 'Verificação bem-sucedida',
  FEEDBACK_VERIFY_FAILED: 'Falha na verificação',
  FEEDBACK_SEARCHING: 'Pesquisando...',
  FEEDBACK_INSIDE_GUIDE: 'Por favor, esteja por dentro do guia',
  FEEDBACK_PLEASE_HOLD: 'Por favor, aguarde.',
  FEEDBACK_STABLE: 'Por favor, fique estável',
  FEEDBACK_EYE_LEVEL: 'Olhe direito',
  FEEDBACK_UP_FACING: 'Olhe para baixo para ficar paralelo à câmera',
  FEEDBACK_DOWN_FACING: 'Olhe para cima para ficar paralelo à câmera',
  FEEDBACK_FACE_OUTSIDE: 'Por favor, esteja por dentro do guia',
  FEEDBACK_CLOSE: 'Afaste se',
  FEEDBACK_FAR: 'Aproxime-se',
  FEEDBACK_LEFT_FACING: 'Olhando para a esquerda, olhe direto',
  FEEDBACK_RIGHT_FACING: 'Olhando certo, por favor, olhe direto',
  FEEDBACK_ROTATED: 'Olhe direto',
  FEEDBACK_NOT_STABLE: 'Por favor, seja estável',
  FEEDBACK_OK: 'Por favor, aguarde',
  FEEDBACK_NO: 'Verificando',
  FEEDBACK_NOT_CENTERED: 'Centro da face em oval',
  FEEDBACK_CAMERA_ACQUIRING_FAILED: 'Não é possível adquirir a câmera, permitir permissão ou tentar capturar novamente',
  FEEDBACK_PROCESSING: 'Processando...',
  FEEDBACK_UPLOAD_FAILURE: 'Erro interno de nova tentativa',
  FEEDBACK_INITIALIZATION: 'Inicializando...',
  FEEDBACK_CHANGE_LIGHT: 'Evite sombras',
  FEEDBACK_CHANGE_LIGHT_TOO_BRIGHT: 'Muito brilhante, mude para uma iluminação melhor.',
  FEEDBACK_CHANGE_LIGHT_TOO_DARK: 'Muito escuro, mude para uma iluminação melhor.',
  FEEDBACK_BUTTON_CLOSE: 'Fechar',
  FEEDBACK_BUTTON_RETRY: 'Tentar novamente',
  FEEDBACK_TRAINING_BUTTON_NEXT: 'Próximo',
  FEEDBACK_TRAINING_LABEL: 'Não mostrar novamente',
  FEEDBACK_ORIENTATION_NOT_SUPPORTED: 'Apenas o modo Retrato é suportado',
  FEEDBACK_TRAINING_DIALOG_CLOSED: 'Diálogo de treinamento, encerrado',
  FEEDBACK_LICENCE_INVALID: 'Licença inválida!!',
  ERROR_BROWSER_NOT_SUPPORTED: 'Navegador não suportado. Use Chrome, Firefox, Opera, Edge (iOS, Android, Desktop), Samsung Internet (Android) ou Safari (iOS, MAC)',
  ERROR_BROWSER_VERSION_NOT_SUPPORTED: 'Versão do navegador não suportada. Atualize para a versão mais recente disponível.',
  ERROR_DEVICE_NOT_SUPPORTED: 'Dispositivo não suportado, use um dispositivo móvel ou desktop.',
  ERROR_DEVICE_NOT_SUPPORTED_ANDROID: 'Versão do Android não suportada. Atualize para a versão mais recente disponível',
  ERROR_DEVICE_NOT_SUPPORTED_IOS: 'Versão IOS não suportada. Atualize para a versão mais recente disponível',
  ERROR_DEVICE_NOT_SUPPORTED_MEMORY: 'Dispositivo não suportado. Memória insuficiente',
  ERROR_PLATFORM_NOT_SUPPORTED_ANDROID: 'Versão do Android não suportada. Atualize para a versão mais recente disponível',
  ERROR_PLATFORM_NOT_SUPPORTED_IOS: 'Versão IOS não suportada para este navegador. Atualize para a versão mais recente disponível',
  ERROR_BROWSER_FEATURE_NOT_SUPPORTED: 'TextDecoder ou Crypto API não presente.',
  ERROR_WEBRTC_NOT_SUPPORTED: 'Webrtc não suportado',
  ERROR_MODEL_FAIL: 'Falha na detecção do modelo',
  ERROR_SERVER_INTERNAL: 'Erro interno do servidor',
  ERROR_SERVER_CONNECTION_FAILURE: 'Falha na conexão com o servidor'
}
