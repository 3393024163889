<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      v-show="show"
      class="modal fade show"
      :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
      @click.self="closeModalBackdrop"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="[{'modal-notice': type === 'notice'}, modalClasses]"
      >
        <div
          class="modal-content"
          :class="[gradient ? `bg-gradient-${gradient}` : '',modalContentClasses]"
        >
          <div
            v-if="$slots.header"
            class="modal-header"
            :class="[headerClasses]"
          >
            <slot name="header" />
            <slot name="close-button">
              <button
                v-if="showClose"
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              >
                <span :aria-hidden="!show">×</span>
              </button>
            </slot>
          </div>
          <div
            class="modal-body"
            :class="bodyClasses"
          >
            <slot />
          </div>
          <div
            v-if="$slots.footer"
            class="modal-footer"
            :class="footerClasses"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>

    </div>
  </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'Modal',
  components: {
    SlideYUpTransition
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Modal type (notice|mini|"") '
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes'
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes'
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)'
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes'
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes'
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes'
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration'
    },
    closeBackdrop: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    show(val) {
      console.log('val: ', val)
      const documentClasses = document.body.classList
      if (val) {
        documentClasses.add('modal-open')
      } else {
        documentClasses.remove('modal-open')
      }
    }
  },
  methods: {
    closeModalBackdrop() {
      if (this.closeBackdrop) {
        this.$emit('update:show', false)
        this.$emit('close')
      }
    },
    closeModal() {
      this.$emit('update:show', false)
      this.$emit('close')
    }
  }
}
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
