import router from '@/router'
import { ToastProgrammatic as Toast } from 'buefy'

// eslint-disable-next-line no-undef
const success = (message) => {
  if (!message) {
    message = router.app.$t('success.default')
  }
  Toast.open({
    duration: 5000,
    message: message,
    position: 'is-top',
    type: 'is-success'
  })
}
// eslint-disable-next-line no-undef
const error = (message) => {
  Toast.open({
    duration: 5000,
    message: message,
    position: 'is-top',
    type: 'is-danger'
  })
}

export const messageHelper = {
  success,
  error
}
