var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-selfie-face" }, [
    _c("div", { staticClass: "position-relative" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "row row-grid justify-content-center" },
          [
            !_vm.selfieActive && !_vm.alert.show
              ? _c("div", { staticClass: "col-lg-12 text-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column justify-space-between align-center",
                    },
                    [
                      _c("div", { staticClass: "flex-item item flex-image" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: "img/icons/selfie/selfie_icon.png",
                              expression: "'img/icons/selfie/selfie_icon.png'",
                            },
                          ],
                          staticClass: "card-img",
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    [
                      _vm._m(0),
                      _c("p", { staticClass: "lead" }, [
                        _vm._v(
                          "\n              Hora de tirar sua foto de identificação.\n            "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "btn-wrapper" },
                        [
                          !_vm.hasError
                            ? _c(
                                "base-button",
                                {
                                  staticClass: "btn btn-primary mb-3 mb-sm-0",
                                  attrs: { icon: "fa fa-camera" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.capture()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Validar selfie\n              "
                                  ),
                                ]
                              )
                            : _c(
                                "base-button",
                                {
                                  staticClass: "btn btn-primary mb-3 mb-sm-0",
                                  attrs: { icon: "fa fa-repeat" },
                                  on: { click: _vm.restartProcess },
                                },
                                [
                                  _vm._v(
                                    "\n                Reiniciar o processo\n              "
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      _vm.hasError
                        ? _c("b-message", { attrs: { type: "is-danger" } }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(`errors.${_vm.errorMsg}`)
                                ),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.alert.show
              ? _c("slide-y-up-transition", [
                  _c(
                    "div",
                    { staticClass: "col-lg-12 text-center alert-message" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-space-between align-center",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-item item flex-image" },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: "img/icons/common/fail.png",
                                    expression: "'img/icons/common/fail.png'",
                                  },
                                ],
                                staticClass: "card-img",
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c("h1", { staticClass: "display-3" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.alert.title) +
                            "\n            "
                        ),
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.alert.message))]),
                      _c(
                        "base-button",
                        {
                          staticClass: "btn btn-primary mb-3 mb-sm-0",
                          on: {
                            click: function ($event) {
                              return _vm.alert.action()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.alert.textButton) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.selfieActive
              ? _c("slide-y-up-transition", [
                  _c(
                    "div",
                    {
                      staticClass: "selfieContainer",
                      attrs: { id: "selfieContainer" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "selfieWebcamParent",
                          attrs: { id: "selfieWebcamParent" },
                        },
                        [
                          _c("div", { staticClass: "selfieWebcam" }, [
                            _c(
                              "div",
                              {
                                staticClass: "selfieContainerParent",
                                attrs: { id: "selfieContainerParent" },
                              },
                              [
                                _c("video", {
                                  staticClass: "posicaoAbsolute",
                                  attrs: {
                                    playsinline: "",
                                    autoplay: "",
                                    muted: "",
                                  },
                                  domProps: { muted: true },
                                }),
                                _c("canvas", {
                                  staticClass: "selfieCanvas",
                                  attrs: { id: "selfieCanvas" },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "selfieMaskCanvas semOverflow centralizado",
                              },
                              [
                                _c("img", {
                                  staticClass: "selfieMaskCanvas centralizado",
                                  attrs: {
                                    id: "selfieMaskId",
                                    src: "img/icons/selfie/SelfieFrameMask.png",
                                  },
                                }),
                                _c("img", {
                                  staticClass: "selfieMaskCanvas centralizado",
                                  attrs: {
                                    id: "selfieMaskIdDetails",
                                    src: "img/icons/selfie/SelfieFrameMaskDetails.png",
                                  },
                                }),
                                _c("img", {
                                  staticClass: "selfieMaskCanvas centralizado",
                                  attrs: {
                                    id: "selfieMaskIdOval",
                                    src: "img/icons/selfie/SelfieFrameMaskOval.png",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "webcamAviso",
                              attrs: { id: "selfieAvisoContainer" },
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass: "selfieTextoAviso",
                                  attrs: { id: "selfieTextoAviso" },
                                },
                                [_vm._v("Aguarde ...")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "selfieBotaoAcaoCancelar",
                              attrs: { id: "selfieBotaoAcaoCancelar" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  id: "botaoAcaoCancelarImagem",
                                  src: "img/icons/selfie/IconFechar.png",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "selfieAlertaErro",
                              staticStyle: { display: "block" },
                              attrs: { id: "selfieAlertaErro" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "centralizado alignCenter card-img",
                                },
                                [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "lazy",
                                        rawName: "v-lazy",
                                        value: "img/icons/selfie/IconFalha.png",
                                        expression:
                                          "'img/icons/selfie/IconFalha.png'",
                                      },
                                    ],
                                    attrs: { id: "selfieAlertaErroImagem" },
                                  }),
                                  _c("p", [_c("b", [_vm._v("Atenção")])]),
                                  _c(
                                    "p",
                                    {
                                      attrs: { id: "selfieAlertaErroMensagem" },
                                    },
                                    [
                                      _vm._v(
                                        "Erro ao validar a selfie, tente novamente"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "botaoAcao",
                                  attrs: {
                                    id: "selfieBotaoAcaoTentarNovamente",
                                  },
                                  on: { click: _vm.restartProcess },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "selfieTextoBotaoAcao" },
                                    [_vm._v("Reiniciar o proceso")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-3" }, [
      _vm._v("Vamos validar"),
      _c("br"),
      _vm._v("sua selfie"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }