<template>
  <div
    class="container-resume"
    :class="{ 'scrollable-container' : isMobile }"
  >
    <div class="data-person-page">
      <section
        v-if="validInit"
        class="section-profile-cover my-0"
      />
      <section class="section section-skew resume-form">
        <div class="container">
          <card
            v-if="validInit"
            shadow
            class="card-profile mt--300"
            no-body
          >
            <div class="px-4">
              <div class="text-center mt-5">
                <h3>Preencha seus dados cadastrais:</h3>
                <div class="h6 font-weight-300"><i class="ni location_pin mr-2" />Lembresse de preencher corretamente as informações.</div>
              </div>
              <div class="mt-5 py-5 border-top text-center">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <b-steps
                        v-model="activeStep"
                        size="is-small"
                        type="is-info"
                        :has-navigation="false"
                      >
                        <b-step-item
                          label="Dados Cadastrais"
                          icon="account-key"
                          class="custom-step-item"
                          :clickable="false"
                        />
                        <b-step-item
                          label="Dados de Endereço"
                          icon="account"
                          class="custom-step-item"
                        />
                      </b-steps>
                    </div>
                    <form
                      id="address-form"
                      @submit="sendValidation"
                    >
                      <base-input
                        v-model="$v.form.zipCode.$model"
                        v-mask="'#####-###'"
                        type="tel"
                        alternative
                        placeholder="CEP"
                        title="cep"
                        :masked="false"
                        addon-left-icon="ni ni-pin-3"
                        :valid="form.zipCode.length && !$v.form.zipCode.$invalid"
                        @blur="fetchAddress"
                      />
                      <base-input
                        v-model="$v.form.address.$model"
                        alternative
                        class="mb-3"
                        placeholder="Endereço"
                        title="endereço"
                        addon-left-icon="ni ni-square-pin"
                        :valid="form.address.length && !$v.form.address.$invalid"
                      />
                      <base-input
                        v-model="$v.form.complement.$model"
                        alternative
                        class="mb-3"
                        placeholder="Complemento"
                        title="complemento"
                        addon-left-icon="ni ni-ungroup"
                        :valid="form.complement.length && !$v.form.complement.$invalid"
                      />
                      <base-input
                        v-model="$v.form.state.$model"
                        alternative
                        class="mb-3"
                        placeholder="Estado"
                        title="estado"
                        addon-left-icon="ni ni-building"
                        :valid="form.state.length && !$v.form.state.$invalid"
                      />
                      <base-input
                        v-model="$v.form.city.$model"
                        alternative
                        class="mb-3"
                        placeholder="Cidade"
                        title="cidade"
                        addon-left-icon="ni ni-shop"
                        :valid="form.city.length && !$v.form.city.$invalid"
                      />
                      <div class="text-center">
                        <base-button
                          type="submit"
                          class="btn btn-primary mb-3 mb-sm-0 my-4"
                          @click="sendValidation()"
                        >Continuar validação</base-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </section>
      <slide-y-up-transition v-if="alert.show">
        <div class="col-lg-12 text-center alert-message">
          <h1 class="display-3 ">
            {{ alert.title }}
          </h1>
          <div class="d-flex flex-column justify-space-between align-center">
            <div class="flex-item item">
              <img
                v-lazy="'img/icons/common/fail.png'"
                class="card-img"
              >
            </div>
          </div>
          <p>{{ alert.message }}</p>
          <base-button
            class="btn btn-primary mb-3 mb-sm-0"
            @click="alert.action()"
          >
            {{ alert.textButton }}
          </base-button>
        </div>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { SlideYUpTransition } from 'vue2-transitions'
import { required, minLength } from 'vuelidate/lib/validators'
import { removeSpecialCharacters } from '@/utils'

export default {
  name: 'ResumeView',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      validInit: false,
      fields: [],
      alert: {
        show: false,
        type: '',
        title: '',
        message: '',
        action: false,
        textButton: ''
      },
      form: {
        address: '',
        complement: '',
        zipCode: '',
        city: '',
        state: ''
      },
      activeStep: 1
    }
  },
  computed: {
    ...mapGetters([
      'device'
    ]),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    this.validStep()
  },
  validations: {
    form: {
      address: { required },
      complement: { required },
      zipCode: { required, minLength: minLength(9) },
      city: { required },
      state: { required }
    }
  },
  methods: {
    ...mapActions([
      'validStepTransaction',
      'resetProcessUser',
      'getCEP',
      'addressTransaction'
    ]),
    async fetchAddress() {
      const cep = removeSpecialCharacters(this.form.zipCode)
      const { success, data } = await this.getCEP(cep)

      if (success) {
        this.form.address = data.data.logradouro
        this.form.complement = data.data.complemento
        this.form.state = data.data.uf
        this.form.city = data.data.localidade
      } else {
        return this.$buefy.toast.open({
          duration: 5000,
          message: `CEP inválido ou não encontrado!`,
          position: 'is-top',
          type: 'is-danger'
        })
      }
    },
    async validStep() {
      const { success, data } = await this.validStepTransaction({ step: 'resume' })
      if (success) {
        const { data: dataInit } = data

        this.validInit = true
        this.fields = dataInit.fields
      } else {
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Opss',
          message: this.$t('errors.error_default_restart_transaction'),
          action: this.restartProcess,
          textButton: 'Reiniciar o processo',
          img: 'fail'
        }
      }
    },
    async sendValidation() {
      if (this.$v.form.$invalid) {
        this.success = false
        return this.$buefy.toast.open({
          duration: 5000,
          message: `Por favor, verifique os campos <b>obrigatórios</b>!`,
          position: 'is-top',
          type: 'is-danger'
        })
      }

      this.validTransactionStart()
    },
    async validTransactionStart() {
      const data = await this.addressTransaction({
        zipCode: removeSpecialCharacters(this.form.zipCode),
        complement: this.form.complement,
        state: this.form.state,
        city: this.form.city
      })

      if (data.success) {
        const { data: dataValid } = data.data

        const nextStep = dataValid.nextStep
        if (nextStep) {
          this.$router.push({
            name: nextStep
          })
        }
      } else {
        const erroData = data.error.data
        const { message: messageKey } = erroData

        this.alert = {
          show: true,
          type: 'danger',
          message: this.$t(`errors.${messageKey}`) || 'Não foi possível validar sua solicitação'
        }
      }
    },
    async restartProcess() {
      const link = await this.resetProcessUser()
      this.$router.push({
        path: '/',
        query: {
          y: link
        }
      })
    }
  }
}
</script>
<style lang="scss">
.scrollable-container {
  overflow-y: auto;
  max-height: 100vh;
}
@media (min-width: 768px) {
  .container-resume {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scrollable-container {
    max-height: 100vh;
  }
}
</style>
