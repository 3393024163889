/**
 * KEY USAGE TO SAVE DATA
 */
export const KEY_TOKEN_LOCAL_STORAGE = 'PORTAL-PROVA-VIDA-HIVEPLACE-TOKEN'
/**
 * KEY USAGE TO SAVE DATA
 */
export const KEY_USER_LOCAL_STORAGE = 'PORTAL-PROVA-VIDA-HIVEPLACE-USER'
/**
 * KEY USAGE TO SAVE DATA
 */
export const KEY_ACCESS_LOCAL_STORAGE = 'PORTAL-PROVA-VIDA-HIVEPLACE-KEY-ACCESS'
