import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home.vue'
import Resume from './views/Resume.vue'
import Selfie from './views/Selfie.vue'
import SelfieFace from './views/SelfieFace.vue'
import Completed from './views/Completed.vue'
import Documents from './views/Documents.vue'
import Address from './views/Address'
import OthersDocuments from './views/OthersDocuments'

Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      components: {
        default: Home
      }
    },
    {
      path: '/selfie/face',
      name: 'selfieFace',
      components: {
        default: SelfieFace
      }
    },
    {
      path: '/selfie',
      name: 'selfieIde',
      components: {
        default: Selfie
      }
    },
    {
      path: '/completed',
      name: 'completed',
      components: {
        default: Completed
      }
    },
    {
      path: '/documents',
      name: 'documents',
      components: {
        default: Documents
      }
    },
    {
      path: '/documents/others',
      name: 'othersDocs',
      components: {
        default: OthersDocuments
      }
    },
    {
      path: '/resume',
      name: 'resumePersonal',
      components: {
        default: Resume
      }
    },
    {
      path: '/address',
      name: 'resumeAddress',
      components: {
        default: Address
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})
