import { errorResponseHelper } from '@/helpers'
import { underLoadingFunction } from '../helpers/under-loading'
import CepHiveService from '@/services/cep.hive.service'

import {
  setToken
} from '@/auth'

const state = {
}

const mutations = {
}

const getters = {
}

const actions = {
  getCEP: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await CepHiveService.getCEP(payload)

      if (data.data && data.data.token) {
        setToken(data.data.token)
      }

      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  })
}

export default {
  state,
  mutations,
  actions,
  getters
}
