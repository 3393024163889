<template>
  <div class="page-selfie">
    <div class="position-relative">
      <section class="section-shaped">
        <div class="container">
          <div class="row row-grid justify-content-center">
            <div
              v-if="selfieActive"
              class="col-lg-12 text-center"
            >
              <h1 class="display-3 ">Vamos validar<br>sua selfie</h1>
              <div class="d-flex flex-column justify-space-between align-center">
                <div
                  class="flex-item item"
                  @click="capture()"
                  @keyup="capture()"
                >
                  <img
                    v-lazy="'img/icons/selfie/selfie_icon.png'"
                    class="card-img"
                  >
                </div>
              </div>
              <div v-if="selfieActive">
                <p class="lead">
                  Hora de tirar sua foto de identificação.
                </p>
                <div class="btn-wrapper">
                  <base-button
                    v-if="!hasError"
                    class="btn btn-primary mb-3 mb-sm-0"
                    icon="fa fa-camera"
                    @click="capture()"
                  >
                    Validar selfie
                  </base-button>
                  <base-button
                    v-else
                    class="btn btn-primary mb-3 mb-sm-0"
                    icon="fa fa-repeat"
                    @click="restartProcess()"
                  >
                    Reiniciar o processo
                  </base-button>
                </div>
                <b-message
                  v-if="hasError"
                  type="is-danger"
                >
                  <span v-html="$t(`errors.${errorMsg}`)" />
                </b-message>
              </div>
            </div>
            <slide-y-up-transition v-else>
              <div class="col-lg-12 text-center alert-message">
                <h1 class="display-3 ">
                  {{ alert.title }}
                </h1>
                <div class="d-flex flex-column justify-space-between align-center">
                  <div class="flex-item item">
                    <img
                      v-lazy="'img/icons/common/fail.png'"
                      class="card-img"
                    >
                  </div>
                </div>
                <p>{{ alert.message }}</p>
                <base-button
                  class="btn btn-primary mb-3 mb-sm-0"
                  @click="alert.action()"
                >
                  {{ alert.textButton }}
                </base-button>
              </div>
            </slide-y-up-transition>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import {
  Base64,
  FaceSDK,
  TransactionMode,
  AsThreshold,
  Template
} from '@identy/identy-face'
import { mapActions, mapGetters } from 'vuex'
import {
  TRANSLATE_SELFIE
} from '@/config/translateSelfie'
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'SelfieView',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      selfieActive: true,
      isLocalhost: false,
      username: '',
      action: '',
      redirect: '',
      active: false,
      displayUserInput: false,
      displayTransaction: false,
      displayProgress: false,
      progressMsg: '',
      errorMsg: '',
      hasError: false,
      alert: {
        show: false,
        type: '',
        title: '',
        message: '',
        action: false,
        textButton: ''
      },
      txData: {
        data: {},
        image: '',
        onclose: () => {
          // Do nothing
        },
        onretry: () => {
          // Do nothing
        }
      },
      message: {
        show: false,
        text: 'Tempo expirado',
        description: 'Ops, seu tempo de sessão expirou. Por favor, reinicie o processo para podermos valiar'
      }
    }
  },
  computed: {
    ...mapGetters({
      client: 'client'
    })
  },
  methods: {
    ...mapActions([
      'validSelfieTransaction',
      'resetProcessUser'
    ]),
    capture() {
      this.selfieActive = true
      this.alert = {
        show: false,
        type: '',
        title: '',
        message: '',
        action: false,
        textButton: ''
      }

      this.runTransaction(TransactionMode.CAPTURE)
    },
    runTransaction(transaction) {
      const options = {
        enableAS: true,
        requiredTemplates: [Template.PNG],
        showCaptureTraining: false,
        base64EncodingFlag: Base64.NO_WRAP,
        allowClose: true,
        enableEyesStatusDetector: true,
        skipSupportCheck: true,
        transaction: {
          type: transaction
        },
        asThreshold: AsThreshold.BALANCED_VERY_HIGH,
        assisted: false,
        events: {
          onImageQualityDetected: (quality) => {
          }
        },
        captureTimeout: 50000,
        graphics: {
          canvas: {
            label: 'white',
            canvasBackground: 'white',
            labelBackground: 'black'
          }
        },
        localization: {
          en: TRANSLATE_SELFIE
        }
      }

      // not exposed field just for testing
      options.returnQualityMetadata = true

      if (transaction === TransactionMode.CAPTURE) {
        if (!options.transaction) {
          options.transaction = {}
        }
        options.transaction.id = localStorage.getItem('transactionId') || ''
      }

      const faceSDK = new FaceSDK(options)

      faceSDK.onInit = () => {
        this.active = true
        faceSDK.capture().then((blob) => {
          this.active = false
          return this.sendValidationSelfie(faceSDK, blob)
        }).catch((err) => {
          this.active = false
          console.log(err)
        })
      }

      faceSDK.initialize().catch((err) => {
        const m = err && err.getLocalizedString
        if (m) {
          if (err.code === 100 || err.code === 104 || err.code === 103 || err.code === 500) {
            this.errorMsg = err.getLocalizedString()
            this.hasError = true
          }

          if (err.code === 600) {
            const clientResponse = { code: err.code, feedbackCode: err.message }
            this.txData.data = clientResponse
            this.txData.options = options
            this.txData.localization = faceSDK.getLocalization()
            this.txData.onretry = () => {
              this.displayTransaction = false
              this.capture()
            }
            this.displayTransaction = true
          }
        }
      })
    },
    async sendValidationSelfie(sdk, capresult) {
      this.progressMsg = 'Uploading...'
      this.displayProgress = true

      const { success, data, error } = await this.validSelfieTransaction(capresult)
      if (success) {
        const { data: dataAnalisys } = data
        if (dataAnalisys.success) {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Selfie validada com sucesso!`,
            position: 'is-top',
            type: 'is-success'
          })

          this.$router.push({
            path: dataAnalisys.nextStep
          })
        }
        if (dataAnalisys.retry) {
          this.selfieActive = false
          this.alert = {
            show: true,
            type: 'danger',
            title: 'Não foi possível validar',
            message: 'Por algum motivo não conseguimos validar sua selfie, vamos repetir o processo?',
            action: this.capture,
            textButton: 'Repetir selfie'
          }
        }
      } else {
        const erroData = error.data
        const { message: messageKey } = erroData

        this.alert = {
          show: true,
          type: 'danger',
          title: 'Não foi possível validar',
          message: this.$t(`errors.${messageKey}`) || 'Não foi possível validar sua solicitação',
          action: this.restartProcess,
          textButton: 'Reiniciar o processo'
        }
        this.selfieActive = false
      }
    },
    async restartProcess() {
      const link = await this.resetProcessUser()
      this.$router.push({
        path: '/',
        query: {
          y: link
        }
      })
    }
  }
}
</script>
<style lang="scss">
.page-selfie {
  .card-img {
    max-width: 180px;
    max-height: 180px;
    margin: .8rem 0;
  }
  .alert-message {
    p {
      margin-bottom: .8rem;
    }
  }
}
</style>
