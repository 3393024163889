/*!
=========================================================
* HIVEPlace - Prova de Vida - v1.0.0
=========================================================

* Product Page: https://liveness.hiveplace.com.br/
* Copyright 2024 HIVEPlace (https://www.hiveplace.com.br)
* Coded by www.hiveplace.com.br
=========================================================
*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Plugins from './plugins/plugins-kit'
import store from './store'
import i18n from './i18n'
import './registerServiceWorker'

import { FaceSDK } from '@identy/identy-face'

import '@identy/identy-face/dist/identy-face-style.css'

import './permission'

FaceSDK.preInitialize({
  URL: `${process.env.VUE_APP_BASE_API}/hive-liveness-portal/selfie/models`
}).catch((err) => {
  console.error(err)
})

Vue.config.productionTip = false
Vue.use(Plugins)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
