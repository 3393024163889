<template>
  <div
    class="page-selfie-face"
    :class="{ 'scrollable-container' : isMobile }"
  >
    <div class="position-relative">
      <div class="container">
        <div class="row row-grid justify-content-center">
          <div
            v-if="!selfieActive && !alert.show"
            class="col-lg-12 text-center"
          >
            <div class="d-flex flex-column justify-space-between align-center">
              <div class="flex-item item flex-image">
                <img
                  v-lazy="'img/icons/selfie/selfie_icon.png'"
                  class="card-img"
                >
              </div>
            </div>
            <div>
              <h1 class="display-3 ">Vamos validar<br>sua selfie</h1>
              <p class="lead">
                Hora de tirar sua foto de identificação.
              </p>
              <div class="btn-wrapper">
                <base-button
                  v-if="!hasError"
                  class="btn btn-primary mb-3 mb-sm-0"
                  icon="fa fa-camera"
                  @click="capture()"
                >
                  Validar selfie
                </base-button>
                <base-button
                  v-else
                  class="btn btn-primary mb-3 mb-sm-0"
                  icon="fa fa-repeat"
                  @click="restartProcess"
                >
                  Reiniciar o processo
                </base-button>
              </div>
              <b-message
                v-if="hasError"
                type="is-danger"
              >
                <span v-html="$t(`errors.${errorMsg}`)" />
              </b-message>
            </div>
          </div>
          <slide-y-up-transition v-if="alert.show">
            <div class="col-lg-12 text-center alert-message">
              <div class="d-flex flex-column justify-space-between align-center">
                <div class="flex-item item flex-image">
                  <img
                    v-lazy="'img/icons/common/fail.png'"
                    class="card-img"
                  >
                </div>
              </div>
              <h1 class="display-3 ">
                {{ alert.title }}
              </h1>
              <p>{{ alert.message }}</p>
              <base-button
                class="btn btn-primary mb-3 mb-sm-0"
                @click="alert.action()"
              >
                {{ alert.textButton }}
              </base-button>
            </div>
          </slide-y-up-transition>
          <!-- selfie container -->
          <slide-y-up-transition v-if="selfieActive">
            <div
              id="selfieContainer"
              class="selfieContainer"
            >
              <div
                id="selfieWebcamParent"
                class="selfieWebcamParent"
              >
                <div class="selfieWebcam">
                  <div
                    id="selfieContainerParent"
                    class="selfieContainerParent"
                  >
                    <video
                      class="posicaoAbsolute"
                      playsinline
                      autoplay
                      muted
                    />
                    <canvas
                      id="selfieCanvas"
                      class="selfieCanvas"
                    />
                  </div>
                  <div class="selfieMaskCanvas semOverflow centralizado">
                    <img
                      id="selfieMaskId"
                      class="selfieMaskCanvas centralizado"
                      src="img/icons/selfie/SelfieFrameMask.png"
                    >
                    <img
                      id="selfieMaskIdDetails"
                      class="selfieMaskCanvas centralizado"
                      src="img/icons/selfie/SelfieFrameMaskDetails.png"
                    >
                    <img
                      id="selfieMaskIdOval"
                      class="selfieMaskCanvas centralizado"
                      src="img/icons/selfie/SelfieFrameMaskOval.png"
                    >
                  </div>
                </div>
                <div
                  id="selfieAvisoContainer"
                  class="webcamAviso"
                ><p
                  id="selfieTextoAviso"
                  class="selfieTextoAviso"
                >Aguarde ...</p></div>
                <div
                  id="selfieBotaoAcaoCancelar"
                  class="selfieBotaoAcaoCancelar"
                ><img
                  id="botaoAcaoCancelarImagem"
                  src="img/icons/selfie/IconFechar.png"
                ></div>
                <div
                  id="selfieAlertaErro"
                  class="selfieAlertaErro"
                  style="display: block;"
                ><div class="centralizado alignCenter card-img"><img
                  id="selfieAlertaErroImagem"
                  v-lazy="'img/icons/selfie/IconFalha.png'"
                ><p><b>Atenção</b></p><p id="selfieAlertaErroMensagem">Erro ao validar a selfie, tente novamente</p></div><div
                  id="selfieBotaoAcaoTentarNovamente"
                  class="botaoAcao"
                  @click="restartProcess"
                ><p class="selfieTextoBotaoAcao">Reiniciar o proceso</p></div></div>
              </div>
            </div>
          </slide-y-up-transition>
          <!-- ./selfie container -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { SlideYUpTransition } from 'vue2-transitions'
import { EnrollmentProcessor } from '@/processors/EnrollmentProcessor'
import ptBR from '@/assets/vendor/sdk-face/optional/pt-br.js'

export default {
  name: 'SelfieFaceView',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      sessionToken: null,
      latestSessionResult: null,
      latestIDScanResult: null,
      selfieActive: false,
      active: false,
      errorMsg: '',
      hasError: false,
      licenceKey: null,
      keyEncrypt: null,
      textKey: null,
      selfieProcess: null,
      alert: {
        show: false,
        type: '',
        title: '',
        message: '',
        action: false,
        textButton: ''
      },
      message: {
        show: false,
        text: 'Tempo expirado',
        description: 'Ops, seu tempo de sessão expirou. Por favor, reinicie o processo para podermos valiar'
      }
    }
  },
  computed: {
    ...mapGetters({
      client: 'client',
      device: 'device'
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    this.validStep()
  },
  methods: {
    ...mapActions([
      'validSelfieFaceTransaction',
      'resetProcessUser',
      'getSessionTokenSelfieFace',
      'validStepTransaction',
      'setLoading'
    ]),
    async validStep() {
      const { success, data } = await this.validStepTransaction({ step: 'selfieFace' })

      if (success) {
        const { data: dataValid } = data
        if (dataValid.success) {
          this.licenceKey = dataValid.key
          this.keyEncrypt = atob(dataValid.keyEncryption)
          this.textKey = atob(dataValid.textKey)
          this.validInit = true

          this.initializeFaceTec()
        } else {
          this.alert = {
            show: true,
            type: 'danger',
            title: 'Opss',
            message: 'Não conseguimos validar o seu passo atual, por favor, reinicie o processo!',
            action: this.restartProcess,
            textButton: 'Reiniciar o processo',
            img: 'fail'
          }
        }
      } else {
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Opss',
          message: this.$t('errors.error_default_restart_transaction'),
          action: this.restartProcess,
          textButton: 'Reiniciar o processo',
          img: 'fail'
        }
      }
    },
    initializeFaceTec() {
      // Configuração dos diretórios de recursos do SDK
      // eslint-disable-next-line no-undef
      FaceTecSDK.setResourceDirectory('lib/sdk-face/sdk-core.js/resources')
      // eslint-disable-next-line no-undef
      FaceTecSDK.setImagesDirectory('lib/sdk-face/sdk_images')
      // eslint-disable-next-line no-undef

      // eslint-disable-next-line no-undef
      FaceTecSDK.initializeInProductionMode(
        this.textKey,
        this.licenceKey,
        this.keyEncrypt,
        (initializationSuccessful) => {
          if (initializationSuccessful) {
            this.initializationStatus = true
            console.log('SDK inicializado com sucesso.')
            // eslint-disable-next-line no-undef
            FaceTecSDK.configureLocalization(ptBR)
          } else {
            console.error('Falha ao inicializar o SDK.')
          }
        }
      )
    },
    resetMessage() {
      this.alert = {
        show: false,
        type: '',
        title: '',
        message: '',
        action: null,
        textButton: ''
      }
    },
    await(segundos) {
      return new Promise(resolve => {
        setTimeout(resolve, segundos * 1000)
      })
    },
    initializeResultObjects() {
      this.latestSessionResult = null
      this.latestIDScanResult = null
    },
    async capture() {
      console.log('Iniciando captura de face...')
      this.initializeResultObjects()
      const { data: dataSessionToken } = await this.getSessionTokenSelfieFace('dFKA6qoaMkydQ1x73F6SwDNdLKl8IkBm')
      if (dataSessionToken.status === 200) {
        this.sessionToken = dataSessionToken.data
        new EnrollmentProcessor(this.sessionToken.sessionToken, this.client)
      }
    },
    processSessionResultWhileFaceTecSDKWaits(sessionResult, faceScanResultCallback) {
      console.log('processSessionResultWhileFaceTecSDKWaits: ')
      // eslint-disable-next-line no-undef
      if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
        faceScanResultCallback.cancel()
        return
      }

      const parameters = {
        faceScan: sessionResult.faceScan,
        auditTrailImage: sessionResult.auditTrail[0],
        sessionId: sessionResult.sessionId
      }

      console.log('parameters: ', parameters)
    },
    onFaceTecSDKCompletelyDone() {
      console.log('Processo do FaceTec SDK concluído.')
    },
    async captureOld() {
      try {
        this.resetMessage()
        this.selfieActive = true
        this.setLoading(true)
        await this.await(2)
        this.setLoading(false)

        const element = document.getElementById('selfieContainer')

        let selfie = this.selfieProcess
        if (!this.selfieProcess) {
          // eslint-disable-next-line no-undef
          selfie = new Selfie(
            this.licenceKey,
            element,
            undefined,
            async(log) => {
              console.log('log', log)
              if (log.id === '10' && log.status === 'Falha') {
                this.selfieProcess.finalizaSelfie()
                this.selfieActive = false
                this.alert = {
                  show: true,
                  type: 'danger',
                  title: 'Ops',
                  message: 'Não foi possível validar sua selfie',
                  action: this.capture,
                  textButton: 'Tentar novamente'
                }
              }
            }
          )
          this.selfieProcess = selfie
        }

        selfie.iniciaSelfie(
          (selfie) => {
            this.sendValidationSelfie(selfie)
          },
          (erroId, mensagem) => {
            console.log('Falha na selfie: ')
            console.log(erroId, mensagem)
            this.alert = {
              show: true,
              type: 'danger',
              title: 'Não foi possível validar',
              message: mensagem,
              action: this.restartProcess,
              textButton: 'Reiniciar o processo'
            }
          }
        )
      } catch (error) {
        console.log('error: ', error)
        this.$buefy.toast.open({
          duration: 8000,
          message: error,
          type: 'is-danger'
        })
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Não foi possível validar sua selfie',
          message: 'Por algum motivo não conseguimos validar sua selfie, vamos repetir o processo?',
          action: this.restartProcess,
          textButton: 'Reiniciar o processo'
        }
      }
    },
    runTransaction(transaction) {
      console.log('transaction: ', transaction)
    },
    async sendValidationSelfie(capresult) {
      try {
        const { success, data, error } = await this.validSelfieFaceTransaction({ capture: capresult })
        if (success) {
          const { data: dataAnalisys } = data

          if (dataAnalisys.success) {
            this.$buefy.toast.open({
              duration: 3000,
              message: `Selfie validada com sucesso!`,
              position: 'is-top',
              type: 'is-success'
            })

            this.await(1)

            this.$router.push({
              name: dataAnalisys.nextStep
            })
          }

          if (dataAnalisys.retry) {
            this.selfieActive = false
            this.alert = {
              show: true,
              type: 'danger',
              title: 'Não foi possível validar',
              message: 'Por algum motivo não conseguimos validar sua selfie, vamos repetir o processo?',
              action: this.capture,
              textButton: 'Repetir selfie'
            }
          }
        } else {
          const erroData = error.data
          const { message: messageKey } = erroData

          this.alert = {
            show: true,
            type: 'danger',
            title: 'Não foi possível validar',
            message: this.$t(`errors.${messageKey}`) || 'Não foi possível validar sua solicitação',
            action: this.restartProcess,
            textButton: 'Reiniciar o processo'
          }
        }
      } catch (error) {
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Ops',
          message: 'Ocorreu uma falha no processo de valição da sua selfie, por favor, tente novamente.',
          action: this.restartProcess,
          textButton: 'Reiniciar o processo'
        }
      }
    },
    async restartProcess() {
      const link = await this.resetProcessUser()
      this.$router.push({
        path: '/',
        query: {
          y: link
        }
      })
    }
  }
}
</script>
<style lang="scss">
.scrollable-container {
  overflow-y: auto;
  max-height: 100vh;
}
.page-selfie-face {
  height: 100%;
  .flex-image {
    margin-top: 4rem;
  }
  .card-img {
    max-width: 180px!important;
    max-height: 180px;
    margin: .8rem 0;
  }
  .alert-message {
    display: block!important;
    p {
      margin-bottom: .8rem;
    }
  }
  .position-relative,
  .container,
  .row {
    height: 100%;
  }
  .btn {
    max-height: 43px;
  }
  img,
  video {
    height: auto;
    max-width: none!important;
  }
}
@media (min-width: 768px) {
  .scrollable-container {
    max-height: 100vh;
  }
}
</style>
