import router from './router'
import { getToken } from '@/auth'

const whiteList = [
  '/',
  '/home',
  '/selfie/face'
]

router.beforeEach(async(to, from, next) => {
  const token = await getToken()

  if (whiteList.indexOf(to.path) !== -1) {
    return next()
  }

  if (token) {
    return next()
  }

  return next(`/`)
})

// router.afterEach(() => {
//   NProgress.done()
// })
