var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-documents" }, [
    _c(
      "div",
      {
        staticClass: "containerDocumentsOutros",
        attrs: { id: "containerDocumentsOutros" },
      },
      [
        _c("div", {}, [
          _c("div", { staticClass: "centralizado alignCenter" }, [
            _c("h2", { staticClass: "display-3" }, [
              _vm._v("Adicionar Outros Documentos"),
            ]),
            _c("p", { staticClass: "lead" }, [
              _vm._v(
                "Para adicionar outros documentos, basta arrastar e soltar os arquivos ou clicar para selecionar."
              ),
            ]),
            _c("div", [
              _c(
                "section",
                { staticClass: "uploadDocWeb" },
                [
                  _c("b-field", {
                    staticClass: "file is-primary",
                    class: { "has-name": !!_vm.file },
                  }),
                  _c(
                    "b-field",
                    [
                      _c(
                        "b-upload",
                        {
                          attrs: { multiple: "", "drag-drop": "" },
                          model: {
                            value: _vm.dropFiles,
                            callback: function ($$v) {
                              _vm.dropFiles = $$v
                            },
                            expression: "dropFiles",
                          },
                        },
                        [
                          _c("section", { staticClass: "section" }, [
                            _c(
                              "div",
                              { staticClass: "content has-text-centered" },
                              [
                                _c(
                                  "p",
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "upload",
                                        size: "is-large",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("p", [
                                  _vm._v(
                                    "Arraste ou clique para fazer o upload!"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "tags" },
                    _vm._l(_vm.dropFiles, function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "tag colorTag" },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(item.name) +
                              "\n                "
                          ),
                          _c("button", {
                            staticClass: "delete is-small",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteDropFile(index)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "containerButton" }, [
              _c("div", { staticClass: "d-grid gap-2 col-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.validTransaction()
                      },
                    },
                  },
                  [_vm._v("\n              Continuar validação\n            ")]
                ),
              ]),
            ]),
          ]),
          _c("div"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }