<template>
  <div id="app">
    <main>
      <fade-transition
        origin="center"
        mode="out-in"
        :duration="250"
      >
        <router-view />
      </fade-transition>
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      />
    </main>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'
import { mapGetters } from 'vuex'
import {
  setLayoutPortal
} from '@/utils'

export default {
  components: {
    FadeTransition
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'client'
    ])
  },
  created() {
    if (this.client['transaction-permition']) {
      setLayoutPortal(this.client)
    }
  }
}
</script>
