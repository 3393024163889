import {
  setDataUser,
  setDataAccess
} from '@/auth'

import * as MutationTypes from '../mutation-types'

import { underLoadingFunction } from '../helpers/under-loading'
// import { errorResponseHelper } from '@/helpers'

const state = {
  token: '',
  roles: [],
  user: {}
}

const mutations = {
  [MutationTypes.SET_TOKEN]: (mutationState, token) => {
    mutationState.token = token
  }
}

const actions = {
  saveDataUser: underLoadingFunction(async({ commit }, { code, key }) => {
    try {
      setDataAccess(key)
      return await setDataUser(code)
    } catch (error) {
      console.log('error: ', error)
      return false
    }
  }),
  resetTokenAccess({ commit }) {
    return new Promise(resolve => {
      commit(MutationTypes.SET_TOKEN, '')
      resolve(true)
    })
  }
}

export default {
  state,
  mutations,
  actions
}
