<template>
  <div class="page-documents">
    <div class="position-relative">
      <section
        v-if="!captureProcess"
        class="section-description-capture"
      >
        <div class="container">
          <div class="row row-grid justify-content-center">
            <div
              v-if="validInit"
              class="col-lg-12 text-center"
            >
              <h1 class="display-3 ">Vamos validar<br>sua identidade</h1>
              <div class="d-flex flex-column justify-space-between align-center">
                <div class="flex-item item">
                  <img
                    v-lazy="'img/icons/common/id-card.png'"
                    class="card-img"
                  >
                </div>
              </div>
              <div v-if="(validInit)">
                <p class="lead">
                  Por favor, tenha à mão seu documento de identificação, como RG, CNH ou qualquer outro documento oficial com foto.
                </p>
                <div class="btn-wrapper">
                  <base-button
                    class="btn btn-primary mb-3 mb-sm-0"
                    icon="fa fa-camera"
                    @click="captureDocuments()"
                  >
                    Validar documento
                  </base-button>
                </div>
              </div>
            </div>
            <slide-y-up-transition v-else>
              <div class="col-lg-12 text-center alert-message">
                <h1 class="display-3 ">
                  {{ alert.title }}
                </h1>
                <div class="d-flex flex-column justify-space-between align-center">
                  <div class="flex-item item">
                    <img
                      v-lazy="`img/icons/common/${alert.img}.png`"
                      class="card-img"
                    >
                  </div>
                </div>
                <p>{{ alert.message }}</p>
                <base-button
                  v-if="alert.textButton"
                  class="btn btn-primary mb-3 mb-sm-0"
                  @click="alert.action()"
                >
                  {{ alert.textButton }}
                </base-button>
              </div>
            </slide-y-up-transition>
          </div>
        </div>
      </section>
      <div
        v-show="captureProcess"
        class="section-capture-document"
      >
        <div id="documento" />
        <img id="imgA">
        <img id="imgB">
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'DocumentsView',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      validInit: false,
      hasError: false,
      captureProcess: false,
      licenceKey: '',
      alert: {
        show: false,
        type: '',
        message: '',
        action: false,
        img: 'validating-process'
      }
    }
  },
  computed: {
    ...mapGetters({
      client: 'client'
    })
  },
  created() {
    this.validStep()
  },
  methods: {
    ...mapActions([
      'validIDDocumentsTransaction',
      'validStepTransaction',
      'resetProcessUser'
    ]),
    async validStep() {
      const { success, data } = await this.validStepTransaction({ step: 'documents' })
      if (success) {
        const { data: dataInit } = data

        this.licenceKey = dataInit.key
        this.validInit = true
      } else {
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Opss',
          message: this.$t('errors.error_default_restart_transaction'),
          action: this.restartProcess,
          textButton: 'Reiniciar o processo',
          img: 'fail'
        }
      }
    },
    captureDocuments() {
      const doc = new window.BrScanSDKDocumento.Documento({
        chave: this.licenceKey
      })

      console.log('Doc version: ', doc.versaoDocumento)

      doc.preloadModelos()

      doc.RNE = true
      doc.timeoutCaptura = 2000

      doc.documentosAceitos = [
        'rg',
        'dni',
        'cin',
        'rne',
        'rnm',
        'cnh',
        'cnhcel2022',
        'cnhdig'
      ]

      doc.wizzard = false
      doc.docSelection = true

      this.captureProcess = true

      doc
        .iniciaCaptura(document.getElementById('documento'))
        .then(documentos => {
          const typeDocument = Array.isArray(documentos.type) ? documentos.type : [documentos.type]

          const payloadSend = {
            documentoIdentificacao: {
              documentType: typeDocument,
              documents: documentos.image,
              side: documentos.side
            }
          }
          this.sendValidation(payloadSend)
          doc.removeHTML()
        })
        .catch(err => {
          console.log(err)
          console.log(err.id, err.desc)
          this.captureProcess = false
        })
    },
    async sendValidation(validation) {
      const { success, data, error } = await this.validIDDocumentsTransaction(validation)
      if (success) {
        const { data: dataAnalisys } = data
        if (dataAnalisys.success) {
          this.$buefy.toast.open({
            duration: 3000,
            message: `Documentos enviados com sucesso!`,
            position: 'is-top',
            type: 'is-success'
          })

          this.$router.push({
            path: dataAnalisys.nextStep
          })
        }

        if (dataAnalisys.retry) {
          this.selfieActive = false
          this.alert = {
            show: true,
            type: 'danger',
            title: 'Não foi possível validar',
            message: 'Por algum motivo não conseguimos validar o documento enviado, vamos repetir o processo?',
            action: this.captureDocuments,
            textButton: 'Repetir validação'
          }
        }
      } else {
        const erroData = error.data
        const { message: messageKey } = erroData

        this.alert = {
          show: true,
          type: 'danger',
          message: this.$t(`errors.${messageKey}`) || 'Não foi possível validar sua solicitação'
        }
      }
    },
    async restartProcess() {
      const link = await this.resetProcessUser()
      this.$router.push({
        path: '/',
        query: {
          y: link
        }
      })
    }
  }
}
</script>
<style lang="scss">
.page-documents {
  height: 100%;
  .position-relative {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .section-description-capture {
      margin: 10% auto;
      @media only screen
        and (max-device-height: 667px)
        and (-webkit-min-device-pixel-ratio: 2) {
          margin: 10% auto;
        }
    }
  }
  .card-img {
    max-width: 180px;
    max-height: 180px;
    margin: .8rem 0;
  }
  .alert-message {
    p {
      margin-bottom: .8rem;
    }
  }
  .section-capture-document {
    width: 100%;
    height: 100vh;
  }
}
</style>
