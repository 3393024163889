var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page-home",
      class: { "scrollable-container": _vm.isMobile },
    },
    [
      _c("section", { staticClass: "section section-centered" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row row-grid justify-content-center" }, [
            _c(
              "div",
              { staticClass: "col-lg-8 text-center" },
              [
                _vm.client.logo
                  ? _c("img", {
                      staticClass: "logo-client",
                      attrs: { src: _vm.client.logo, alt: "logotipo" },
                    })
                  : _vm._e(),
                _vm.verifyStart
                  ? _c("div", [
                      _c("h2", { staticClass: "display-3" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _c("p", { staticClass: "lead" }, [
                        _vm._v(_vm._s(_vm.description)),
                      ]),
                    ])
                  : _vm._e(),
                !_vm.verifyStart && _vm.alert.show
                  ? _c("b-message", { attrs: { type: "is-danger" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.alert.message) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
                _vm.verifyStart
                  ? _c(
                      "div",
                      { staticClass: "btn-wrapper" },
                      [
                        _vm.alert.show
                          ? _c(
                              "base-alert",
                              {
                                attrs: {
                                  type: _vm.alert.type,
                                  icon: "fa fa-exclamation-circle",
                                  dismissible: "",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "text" }, slot: "text" },
                                  [_vm._v(_vm._s(_vm.alert.message))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "form",
                          {
                            staticClass: "form-alignment",
                            attrs: { id: "init-validation-form" },
                            on: { submit: _vm.validData },
                          },
                          [
                            _vm.verifyStart
                              ? _c("base-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "###.###.###-##",
                                      expression: "'###.###.###-##'",
                                    },
                                  ],
                                  staticClass: "input-cpf",
                                  attrs: {
                                    type: "tel",
                                    placeholder: "000.000.000-00",
                                    label: "CPF",
                                    title: "CPF",
                                    valid: _vm.success,
                                    masked: false,
                                    error: _vm.$v.form.document.$error
                                      ? "CPF inválido, verifique e tente novamente"
                                      : "",
                                  },
                                  model: {
                                    value: _vm.form.document,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "document", $$v)
                                    },
                                    expression: "form.document",
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "base-checkbox",
                              {
                                staticClass: "mb-3",
                                model: {
                                  value: _vm.form.terms,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "terms", $$v)
                                  },
                                  expression: "form.terms",
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Eu concordo com a\n                  "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blanck",
                                        href: _vm.client.terms,
                                      },
                                    },
                                    [_vm._v("política de privacidade")]
                                  ),
                                ]),
                              ]
                            ),
                            _vm.verifyStart
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary mb-3 mb-sm-0",
                                    attrs: { type: "submit" },
                                  },
                                  [_vm._v(_vm._s(_vm.textInit))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _c("span", { staticClass: "tag-version" }, [
          _vm._v("\n      V " + _vm._s(_vm.version) + "\n    "),
        ]),
      ]),
      _c(
        "modal",
        {
          attrs: {
            show: _vm.notification.show,
            gradient: _vm.notification.type,
            "show-close": false,
            "close-backdrop": false,
            "modal-classes": "modal-danger modal-dialog-centered",
          },
          on: {
            "update:show": function ($event) {
              return _vm.$set(_vm.notification, "show", $event)
            },
          },
          model: {
            value: _vm.form.document,
            callback: function ($$v) {
              _vm.$set(_vm.form, "document", $$v)
            },
            expression: "form.document",
          },
        },
        [
          _c("div", { staticClass: "py-3 text-center" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: "img/icons/common/error.png",
                  expression: "'img/icons/common/error.png'",
                },
              ],
            }),
            _vm.notification.title
              ? _c("h6", { staticClass: "title-default m-4" }, [
                  _vm._v(_vm._s(_vm.notification.title)),
                ])
              : _vm._e(),
            _c("p", { staticClass: "mt-4" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.notification.message) + "\n      "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }