import BaseService from '@/config/base-service'

class SelfieHiveService extends BaseService {
  constructor() {
    super(`hive-liveness-portal`)
  }

  getSessionTokenSelfieFace(key) {
    const config = {
      headers: {
        'X-Device-Key': key,
        // eslint-disable-next-line no-undef
        'X-User-Agent': FaceTecSDK.createFaceTecAPIUserAgentString('')
      }
    }
    return this.get(`selfie-face/session-token`, config)
  }

  validSelfieTransaction(payload) {
    const payloadFormData = new FormData()
    payloadFormData.append('file', payload, 'bdata')

    const config = {
      params: {
        ts: new Date().getTime()
      },
      headers: {
        'X-DEBUG': 'teste',
        'Content-Type': 'multipart/form-data'
      }
    }

    return this.post(payloadFormData, `selfie/valid`, config)
  }

  validSelfieFaceTransaction(payload, agent) {
    const headers = {
      'X-User-Agent': agent
    }
    return this.post(payload, `selfie/face/valid`, { headers })
  }
}

export default new SelfieHiveService()
