// APP
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR'
export const TOGGLE_DEVICE = 'TOGGLE_DEVICE'
export const SET_SIZE = 'SET_SIZE'
export const SET_LOADING = 'SET_LOADING'
export const SET_TEXT_LOADING = 'SET_TEXT_LOADING'
export const UPDATE_HEADER = 'UPDATE_HEADER'
export const SET_CLIENT = 'SET_CLIENT'
export const SET_FLOW = 'SET_FLOW'

// USER
export const SET_TOKEN = 'SET_TOKEN'
