var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-selfie" }, [
    _c("div", { staticClass: "position-relative" }, [
      _c("section", { staticClass: "section-shaped" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "row row-grid justify-content-center" },
            [
              _vm.selfieActive
                ? _c("div", { staticClass: "col-lg-12 text-center" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column justify-space-between align-center",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-item item",
                            on: {
                              click: function ($event) {
                                return _vm.capture()
                              },
                              keyup: function ($event) {
                                return _vm.capture()
                              },
                            },
                          },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: "img/icons/selfie/selfie_icon.png",
                                  expression:
                                    "'img/icons/selfie/selfie_icon.png'",
                                },
                              ],
                              staticClass: "card-img",
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm.selfieActive
                      ? _c(
                          "div",
                          [
                            _c("p", { staticClass: "lead" }, [
                              _vm._v(
                                "\n                Hora de tirar sua foto de identificação.\n              "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "btn-wrapper" },
                              [
                                !_vm.hasError
                                  ? _c(
                                      "base-button",
                                      {
                                        staticClass:
                                          "btn btn-primary mb-3 mb-sm-0",
                                        attrs: { icon: "fa fa-camera" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.capture()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Validar selfie\n                "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "base-button",
                                      {
                                        staticClass:
                                          "btn btn-primary mb-3 mb-sm-0",
                                        attrs: { icon: "fa fa-repeat" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.restartProcess()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Reiniciar o processo\n                "
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                            _vm.hasError
                              ? _c(
                                  "b-message",
                                  { attrs: { type: "is-danger" } },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(`errors.${_vm.errorMsg}`)
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _c("slide-y-up-transition", [
                    _c(
                      "div",
                      { staticClass: "col-lg-12 text-center alert-message" },
                      [
                        _c("h1", { staticClass: "display-3" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.alert.title) +
                              "\n              "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column justify-space-between align-center",
                          },
                          [
                            _c("div", { staticClass: "flex-item item" }, [
                              _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: "img/icons/common/fail.png",
                                    expression: "'img/icons/common/fail.png'",
                                  },
                                ],
                                staticClass: "card-img",
                              }),
                            ]),
                          ]
                        ),
                        _c("p", [_vm._v(_vm._s(_vm.alert.message))]),
                        _c(
                          "base-button",
                          {
                            staticClass: "btn btn-primary mb-3 mb-sm-0",
                            on: {
                              click: function ($event) {
                                return _vm.alert.action()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.alert.textButton) +
                                "\n              "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "display-3" }, [
      _vm._v("Vamos validar"),
      _c("br"),
      _vm._v("sua selfie"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }