var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-completed" }, [
    _c("div", { staticClass: "position-relative" }, [
      _c("section", { staticClass: "section-shaped" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _vm.validInit
              ? _c(
                  "div",
                  { staticClass: "row row-grid justify-content-center" },
                  [
                    _c("div", { staticClass: "col-lg-12 text-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-space-between align-center",
                        },
                        [
                          _c("div", { staticClass: "flex-item item" }, [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: "img/icons/common/success.png",
                                  expression: "'img/icons/common/success.png'",
                                },
                              ],
                              staticClass: "card-img",
                            }),
                          ]),
                        ]
                      ),
                      _c("h1", { staticClass: "display-3" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("views.completed.title")) +
                            "\n            "
                        ),
                      ]),
                      _c(
                        "div",
                        [
                          !_vm.externalLink
                            ? _c("p", { staticClass: "lead" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("views.completed.message")) +
                                    "\n              "
                                ),
                              ])
                            : _c("p", { staticClass: "lead" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.completed.messageLinkRedirect"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                          _vm.externalLink
                            ? _c(
                                "base-button",
                                {
                                  staticClass: "btn btn-link mb-3 mb-sm-0 my-3",
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectLink()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.completed.textButtonRedirect"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.alert.show
              ? _c("slide-y-up-transition", [
                  _c(
                    "div",
                    { staticClass: "col-lg-12 text-center alert-message" },
                    [
                      _c("h1", { staticClass: "display-3" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.alert.title) +
                            "\n            "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-column justify-space-between align-center",
                        },
                        [
                          _c("div", { staticClass: "flex-item item" }, [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: "img/icons/common/fail.png",
                                  expression: "'img/icons/common/fail.png'",
                                },
                              ],
                              staticClass: "card-img",
                            }),
                          ]),
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.alert.message))]),
                      _c(
                        "base-button",
                        {
                          staticClass: "btn btn-primary mb-3 mb-sm-0 my-3",
                          on: {
                            click: function ($event) {
                              return _vm.alert.action()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.alert.textButton) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }