import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ptBR from '@/locales/pt-BR.json'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const messages = {
    pt: ptBR
  }
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt',
  messages: loadLocaleMessages()
})
