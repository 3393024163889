<template>
  <div
    class="container-resume"
    :class="{ 'scrollable-container' : isMobile }"
  >
    <div class="data-person-page">
      <section
        v-if="validInit"
        class="section-profile-cover my-0"
      />
      <section class="section section-skew resume-form">
        <div class="container">
          <card
            v-if="validInit"
            shadow
            class="card-profile mt--300"
            no-body
          >
            <div class="px-4">
              <div class="text-center mt-5">
                <h3>Preencha seus dados cadastrais:</h3>
                <div class="h6 font-weight-300"><i class="ni location_pin mr-2" />Lembresse de preencher corretamente as informações.</div>
              </div>
              <div class="mt-5 py-5 border-top text-center">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <b-steps
                        v-model="activeStep"
                        size="is-small"
                        type="is-info"
                        :has-navigation="false"
                      >
                        <b-step-item
                          label="Dados Cadastrais"
                          icon="account-key"
                          class="custom-step-item"
                        />
                        <b-step-item
                          label="Endereço"
                          icon="account"
                          class="custom-step-item"
                        />
                      </b-steps>
                    </div>
                    <form
                      id="resume-form"
                      @submit="sendValidation"
                    >
                      <base-input
                        v-model="$v.form.name.$model"
                        alternative
                        class="mb-3"
                        placeholder="Nome completo"
                        title="nome"
                        addon-left-icon="ni ni-circle-08"
                        type="text"
                        :valid="form.name.length && !$v.form.name.$invalid"
                      />
                      <base-input
                        v-model="$v.form.phone.$model"
                        v-mask="'(##) #####-####'"
                        type="tel"
                        alternative
                        placeholder="Telefone"
                        title="telefone"
                        :masked="false"
                        addon-left-icon="ni ni-mobile-button"
                        :valid="form.phone.length && !$v.form.phone.$invalid"
                      />
                      <base-input
                        v-model="$v.form.email.$model"
                        alternative
                        class="mb-3"
                        placeholder="E-mail"
                        title="email"
                        addon-left-icon="ni ni-email-83"
                        type="text"
                        :valid="form.email.length && !$v.form.email.$invalid"
                      />
                      <base-input
                        v-model="$v.form.birthdate.$model"
                        v-mask="'##/##/####'"
                        alternative
                        class="mb-3"
                        placeholder="Data de nascimento"
                        title="data-nascimento"
                        addon-left-icon="ni ni-calendar-grid-58"
                        type="tel"
                        :valid="form.birthdate.length && !$v.form.birthdate.$invalid"
                      />
                      <div class="text-center">
                        <base-button
                          type="submit"
                          class="btn btn-primary mb-3 mb-sm-0 my-4"
                          @click="sendValidation()"
                        >Continuar validação</base-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </section>
      <slide-y-up-transition v-if="alert.show">
        <div class="col-lg-12 text-center alert-message">
          <h1 class="display-3 ">
            {{ alert.title }}
          </h1>
          <div class="d-flex flex-column justify-space-between align-center">
            <div class="flex-item item">
              <img
                v-lazy="'img/icons/common/fail.png'"
                class="card-img"
              >
            </div>
          </div>
          <p>{{ alert.message }}</p>
          <base-button
            class="btn btn-primary mb-3 mb-sm-0"
            @click="alert.action()"
          >
            {{ alert.textButton }}
          </base-button>
        </div>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { SlideYUpTransition } from 'vue2-transitions'
import { required, minLength } from 'vuelidate/lib/validators'
import { validateEmail } from '@/utils/validate'
import { removeSpecialCharacters } from '@/utils'

const validEmail = (value) => validateEmail(value)

export default {
  name: 'ResumeView',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      validInit: false,
      fields: [],
      alert: {
        show: false,
        type: '',
        title: '',
        message: '',
        action: false,
        textButton: ''
      },
      form: {
        name: '',
        email: '',
        birthdate: '',
        phone: ''
      },
      activeStep: 0
    }
  },
  computed: {
    ...mapGetters([
      'device'
    ]),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    this.validStep()
  },
  validations: {
    form: {
      name: { required },
      email: { required, validEmail },
      birthdate: { required, minLength: minLength(10) },
      phone: { required, minLength: minLength(15) }
    }
  },
  methods: {
    ...mapActions([
      'validStepTransaction',
      'resetProcessUser',
      'resumeTransaction'
    ]),
    async validStep() {
      const { success, data } = await this.validStepTransaction({ step: 'resumePersonal' })
      if (success) {
        const { data: dataInit } = data

        this.validInit = true
        this.fields = dataInit.fields
      } else {
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Opss',
          message: this.$t('errors.error_default_restart_transaction'),
          action: this.restartProcess,
          textButton: 'Reiniciar o processo',
          img: 'fail'
        }
      }
    },
    async sendValidation() {
      if (this.$v.form.$invalid) {
        return this.$buefy.toast.open({
          duration: 5000,
          message: `Por favor, verifique os campos <b>obrigatórios</b>!`,
          position: 'is-top',
          type: 'is-danger'
        })
      }

      this.validTransactionStart()
    },
    async validTransactionStart() {
      const data = await this.resumeTransaction({
        phone: removeSpecialCharacters(this.form.phone),
        birthdate: removeSpecialCharacters(this.form.birthdate),
        email: this.form.email,
        name: this.form.name
      })

      if (data.success) {
        this.$router.push({
          name: data.data.data.nextStep
        })
      } else {
        const erroData = data.error.data
        const { message: messageKey } = erroData

        this.alert = {
          show: true,
          type: 'danger',
          message: this.$t(`errors.${messageKey}`) || 'Não foi possível validar sua solicitação'
        }
      }
    },
    async restartProcess() {
      const link = await this.resetProcessUser()
      this.$router.push({
        path: '/',
        query: {
          y: link
        }
      })
    }
  }
}
</script>
<style lang="scss">
.scrollable-container {
  overflow-y: auto;
  max-height: 100vh;
}
.step-items {
  color: #32325d;
  font-size: 0.5rem;
  font-weight: 100;
}
.step-link {
  gap: 0.6rem;
}
@media (min-width: 768px) {
  .container-resume {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scrollable-container {
    max-height: 100vh;
  }
  .resume-form {
    width: 600px;
  }
}
</style>
