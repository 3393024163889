<template>
  <div id="app">
    <main>
      <fade-transition
        origin="center"
        mode="out-in"
        :duration="250"
      >
        <router-view />
      </fade-transition>
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      />
    </main>
  </div>
</template>
<script>
import { FadeTransition } from 'vue2-transitions'
import { mapGetters, mapActions } from 'vuex'
import {
  setLayoutPortal
} from '@/utils'

const { body } = document
const WIDTH = 992 // refer to Bootstrap's responsive design

export default {
  components: {
    FadeTransition
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'client'
    ])
  },
  created() {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      this.toggleDevice('mobile')
      document.body.classList.add('mobile')
    }
    if (this.client['transaction-permition']) {
      setLayoutPortal(this.client)
    }
  },
  methods: {
    ...mapActions([
      'toggleDevice'
    ]),
    $_isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    }
  }
}
</script>
