import * as MutationTypes from '../mutation-types'

export function underLoadingFunction(actionFn) {
  return async function(context, payload) {
    context.commit(MutationTypes.SET_LOADING, true)
    try {
      return await actionFn.call(this, context, payload)
    } finally {
      context.commit(MutationTypes.SET_LOADING, false)
    }
  }
}
