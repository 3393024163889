import { errorResponseHelper } from '@/helpers'
import { underLoadingFunction } from '../helpers/under-loading'
import LivenessHiveService from '@/services/liveness.hive.service'

import {
  setToken,
  setDataUser
} from '@/auth'

const state = {
}

const mutations = {
}

const getters = {
}

const actions = {
  validInitTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await LivenessHiveService.validInitTransaction(payload)
      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  }),
  startFlowTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await LivenessHiveService.startFlowTransaction(payload)

      if (data.data && data.data.token) {
        setToken(data.data.token)
      }

      if (data.data && data.data.newCode) {
        setDataUser(data.data.newCode)
      }

      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  }),
  validIDDocumentsTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await LivenessHiveService.validIDDocumentsTransaction(payload)
      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  }),
  resumeTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await LivenessHiveService.resumeTransaction(payload)

      if (data.data && data.data.token) {
        setToken(data.data.token)
      }

      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  }),
  addressTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await LivenessHiveService.addressTransaction(payload)

      if (data.data && data.data.token) {
        setToken(data.data.token)
      }

      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  }),
  validOthersDocumentsTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await LivenessHiveService.validOthersDocumentsTransaction(payload)

      if (data.data && data.data.token) {
        setToken(data.data.token)
      }

      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  })
}

export default {
  state,
  mutations,
  actions,
  getters
}
