import BaseService from '@/config/base-service'

class LivenessHiveService extends BaseService {
  constructor() {
    super(`hive-liveness-portal`)
  }

  validInitTransaction(payload, params) {
    return this.post(payload, `portal/valid-client`, { params })
  }

  startFlowTransaction(payload, params) {
    return this.post(payload, `portal/init`, { params })
  }

  resumeTransaction(payload, params) {
    return this.post(payload, `resume/registration-data`, { params })
  }

  addressTransaction(payload, params) {
    return this.post(payload, `resume/address-data`, { params })
  }

  validIDDocumentsTransaction(payload, params) {
    return this.post(payload, `documents/id/valid`, { params })
  }

  validOthersDocumentsTransaction(payload, params) {
    // return
  }
}

export default new LivenessHiveService()
