<template>
  <div class="page-completed">
    <div class="position-relative">
      <section
        class="section-shaped"
      >
        <div class="container">
          <div
            v-if="validInit"
            class="row row-grid justify-content-center"
          >
            <div class="col-lg-12 text-center">
              <div class="d-flex flex-column justify-space-between align-center">
                <div class="flex-item item">
                  <img
                    v-lazy="'img/icons/common/success.png'"
                    class="card-img"
                  >
                </div>
              </div>
              <h1 class="display-3 ">
                {{ $t('views.completed.title') }}
              </h1>
              <div>
                <p
                  v-if="!externalLink"
                  class="lead"
                >
                  {{ $t('views.completed.message') }}
                </p>
                <p
                  v-else
                  class="lead"
                >
                  {{ $t('views.completed.messageLinkRedirect') }}
                </p>
                <base-button
                  v-if="externalLink"
                  class="btn btn-link mb-3 mb-sm-0 my-3"
                  @click="redirectLink()"
                >
                  {{ $t('views.completed.textButtonRedirect') }}
                </base-button>
              </div>
            </div>
          </div>
          <slide-y-up-transition v-if="alert.show">
            <div class="col-lg-12 text-center alert-message">
              <h1 class="display-3 ">
                {{ alert.title }}
              </h1>
              <div class="d-flex flex-column justify-space-between align-center">
                <div class="flex-item item">
                  <img
                    v-lazy="'img/icons/common/fail.png'"
                    class="card-img"
                  >
                </div>
              </div>
              <p>{{ alert.message }}</p>
              <base-button
                class="btn btn-primary mb-3 mb-sm-0 my-3"
                @click="alert.action()"
              >
                {{ alert.textButton }}
              </base-button>
            </div>
          </slide-y-up-transition>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'CompletedView',
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      validInit: false,
      externalLink: null,
      alert: {
        show: false,
        type: '',
        title: '',
        message: '',
        action: false,
        textButton: ''
      }
    }
  },
  created() {
    this.validStep()
  },
  methods: {
    ...mapActions([
      'validStepTransaction',
      'resetProcessUser'
    ]),
    async validStep() {
      const { success, data } = await this.validStepTransaction({ step: 'completed' })
      if (success) {
        const { data: dataValid } = data
        if (dataValid.success) {
          this.validInit = true
          if (dataValid.externalLink) {
            this.externalLink = dataValid.externalLink
            this.redirectExternal()
          }
        } else {
          this.alert = {
            show: true,
            type: 'danger',
            title: 'Opss',
            message: this.$t(`errors.${dataValid.message}`),
            action: this.restartProcess,
            textButton: 'Reiniciar o processo',
            img: 'fail'
          }
        }
      } else {
        this.alert = {
          show: true,
          type: 'danger',
          title: 'Opss',
          message: this.$t('errors.error_default_restart_transaction'),
          action: this.restartProcess,
          textButton: 'Reiniciar o processo',
          img: 'fail'
        }
      }
    },
    async redirectExternal() {
      setTimeout(() => {
        window.location.href = this.externalLink
      }, 6000)
    },
    async redirectLink() {
      window.location.href = this.externalLink
    },
    async restartProcess() {
      const link = await this.resetProcessUser()
      this.$router.push({
        path: '/',
        query: {
          y: link
        }
      })
    }
  }
}
</script>
<style lang="scss">
.page-completed {
  .card-img {
    max-width: 180px;
    max-height: 180px;
    margin: .8rem 0;
  }
}
</style>
