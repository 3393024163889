import { errorResponseHelper } from '@/helpers'
import { underLoadingFunction } from '../helpers/under-loading'
import SelfieHiveService from '@/services/selfie.hive.service'

const state = {
}

const mutations = {
}

const getters = {
}

const actions = {
  getSessionTokenSelfieFace: underLoadingFunction(async({ commit }, params) => {
    try {
      const { data } = await SelfieHiveService.getSessionTokenSelfieFace(params)
      return { success: true, data }
    } catch (error) {
      console.log('error: ', error)
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  }),
  validSelfieTransaction: underLoadingFunction(async({ commit }, payload) => {
    try {
      const { data } = await SelfieHiveService.validSelfieTransaction(payload)
      return { success: true, data }
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  }),
  validSelfieFaceTransaction: underLoadingFunction(async({ commit }, { payload, agent }) => {
    try {
      const { data } = await SelfieHiveService.validSelfieFaceTransaction(payload, agent)
      return data.status === 200 ? data.data : data
    } catch (error) {
      errorResponseHelper.display(error)
      return { success: false, error }
    }
  })
}

export default {
  state,
  mutations,
  actions,
  getters
}
