import {
  KEY_USER_LOCAL_STORAGE,
  KEY_ACCESS_LOCAL_STORAGE,
  KEY_TOKEN_LOCAL_STORAGE
} from '@/config/constants'

import Cookies from 'js-cookie'

export async function setToken(data, exp = 6000) {
  return Cookies.set(KEY_TOKEN_LOCAL_STORAGE, data, { secure: true, sameSite: 'None', expires: exp, domain: process.env.VUE_APP_URI_DOMAIN })
}

export async function setDataUser(data, exp = 6000) {
  await Cookies.remove(KEY_USER_LOCAL_STORAGE)
  return Cookies.set(KEY_USER_LOCAL_STORAGE, data, { secure: true, sameSite: 'None', expires: exp, domain: process.env.VUE_APP_URI_DOMAIN })
}

export async function setDataAccess(data, exp = 6000) {
  return Cookies.set(KEY_ACCESS_LOCAL_STORAGE, data, { secure: true, sameSite: 'None', expires: exp, domain: process.env.VUE_APP_URI_DOMAIN })
}

export async function getToken() {
  return Cookies.get(KEY_TOKEN_LOCAL_STORAGE)
}

export async function getUserData() {
  return Cookies.get(KEY_USER_LOCAL_STORAGE)
}

export async function getAccessData() {
  return Cookies.get(KEY_ACCESS_LOCAL_STORAGE)
}

export function clearToken() {
  Cookies.remove(KEY_USER_LOCAL_STORAGE)
  Cookies.remove(KEY_TOKEN_LOCAL_STORAGE)
  Cookies.remove(KEY_ACCESS_LOCAL_STORAGE)
  return true
}
