<template>
  <div
    class="page-home"
    :class="{ 'scrollable-container' : isMobile }"
  >
    <section class="section section-centered">
      <div class="container">
        <div class="row row-grid justify-content-center">
          <div class="col-lg-8 text-center">
            <img
              v-if="client.logo"
              class="logo-client"
              :src="client.logo"
              alt="logotipo"
            >
            <div v-if="verifyStart">
              <h2 class="display-3">{{ title }}</h2>
              <p class="lead">{{ description }}</p>
            </div>
            <b-message
              v-if="!verifyStart && alert.show"
              type="is-danger"
            >
              {{ alert.message }}
            </b-message>
            <div
              v-if="verifyStart"
              class="btn-wrapper"
            >
              <base-alert
                v-if="alert.show"
                :type="alert.type"
                icon="fa fa-exclamation-circle"
                dismissible
              >
                <span slot="text">{{ alert.message }}</span>
              </base-alert>
              <form
                id="init-validation-form"
                class="form-alignment"
                @submit="validData"
              >
                <base-input
                  v-if="verifyStart"
                  v-model="form.document"
                  v-mask="'###.###.###-##'"
                  type="tel"
                  placeholder="000.000.000-00"
                  label="CPF"
                  title="CPF"
                  :valid="success"
                  :masked="false"
                  :error="$v.form.document.$error ? 'CPF inválido, verifique e tente novamente' : ''"
                  class="input-cpf"
                />
                <base-checkbox
                  v-model="form.terms"
                  class="mb-3"
                >
                  <span>Eu concordo com a
                    <a
                      target="_blanck"
                      :href="client.terms"
                    >política de privacidade</a>
                  </span>
                </base-checkbox>
                <button
                  v-if="verifyStart"
                  type="submit"
                  class="btn btn-primary mb-3 mb-sm-0"
                >{{ textInit }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <span class="tag-version">
        V {{ version }}
      </span>
    </section>
    <modal
      v-model="form.document"
      :show.sync="notification.show"
      :gradient="notification.type"
      :show-close="false"
      :close-backdrop="false"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <div class="py-3 text-center">
        <img
          v-lazy="'img/icons/common/error.png'"
        >
        <h6
          v-if="notification.title"
          class="title-default m-4"
        >{{ notification.title }}</h6>
        <p class="mt-4">
          {{ notification.message }}
        </p>
      </div>
    </modal>
  </div>
</template>
<script>
// import {
//   checkDeviceIsMobile
// } from '@/utils/validate'
import Modal from '@/components/Modal.vue'
import { required } from 'vuelidate/lib/validators'
import { validateCPF } from '@/utils/validate'
import { mapActions, mapGetters } from 'vuex'
import version from '@/version'
import {
  setLayoutPortal,
  removeSpecialCharacters
} from '@/utils'

const validCPF = (value) => validateCPF(value)

export default {
  name: 'HomeView',
  components: {
    Modal
  },
  data() {
    return {
      keyValid: null,
      title: 'Seja bem-vindo(a)',
      description: 'Confirme o seu CPF, para darmos seguimento a sua validação:',
      textInit: 'Iniciar validação',
      verifyStart: false,
      version: version,
      alert: {
        show: false,
        type: 'danger',
        message: ''
      },
      notification: {
        show: false,
        type: 'danger',
        showAction: false,
        title: '',
        message: ''
      },
      form: {
        document: ''
      },
      success: null
    }
  },
  computed: {
    ...mapGetters({
      client: 'client',
      device: 'device'
    }),
    isMobile() {
      return this.device === 'mobile'
    }
  },
  created() {
    this.keyValid = this.$route.query.y

    // if (!checkDeviceIsMobile()) {
    //   this.notification.show = true
    //   this.notification.title = 'Dispositivo não permitido'
    //   this.notification.message = 'Por favor, para prosseguir acesso pelo seu celular!'
    //   return
    // }

    if (!this.keyValid) {
      this.notification.show = true
      this.notification.title = 'Cliente não identificado'
      this.notification.message = 'Não conseguimos identificar o cliente com base nas informações fornecidas. Por favor, verifique o endereço do portal e tente novamente.'
      return
    }

    this.validInit()
  },
  validations: {
    form: {
      document: { required, validCPF },
      terms: { required }
    }
  },
  methods: {
    ...mapActions([
      'setClient',
      'validInitTransaction',
      'startFlowTransaction',
      'saveDataUser',
      'setFlowTransaction'
    ]),
    clearQuery() {
      const novaURL = window.location.protocol + '//' + window.location.host + window.location.pathname
      window.history.replaceState({}, document.title, novaURL)
    },
    async validInit() {
      const data = await this.validInitTransaction({ code: this.keyValid })
      if (data.success) {
        const { data: dataValid } = data.data

        this.setClient(dataValid)
        setLayoutPortal(dataValid)

        this.verifyStart = true
        this.saveDataUser({ code: dataValid.code, key: this.keyValid })
      } else {
        this.alert = {
          show: true,
          type: 'danger',
          message: this.$t('messages.not_start')
        }
      }
    },
    validData(e) {
      e.preventDefault()
      this.$v.form.$touch()

      if (this.$v.form.$invalid) {
        this.success = false
        return this.$buefy.toast.open({
          duration: 5000,
          message: `Por favor, verifique os campos <b>obrigatórios</b>!`,
          position: 'is-top',
          type: 'is-danger'
        })
      }
      this.validTransactionStart()
    },
    async validTransactionStart() {
      const data = await this.startFlowTransaction({
        document: removeSpecialCharacters(this.form.document)
      })

      if (data.success) {
        const { data: dataValid } = data.data

        this.setFlowTransaction(dataValid.flow)

        const nextStep = dataValid.nextStep
        if (nextStep) {
          this.$router.push({
            name: nextStep
          })
        }
      } else {
        const erroData = data.error.data
        const { message: messageKey } = erroData

        this.alert = {
          show: true,
          type: 'danger',
          message: this.$t(`errors.${messageKey}`) || 'Não foi possível validar sua solicitação'
        }
      }
    }
  }
}
</script>
<style lang="scss">
.scrollable-container {
  overflow-y: auto;
  max-height: 100vh;
}
.page-home {
  .form-alignment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .lead {
    margin-bottom: 10px;
  }
  .input-cpf {
    width: 100%;
    text-align: left;
    font-weight: 500;
  }
  @media (min-width: 768px) {
    .input-cpf {
      width: 250px;
    }
    .scrollable-container {
      max-height: 100vh;
    }
  }
}
</style>
