import router from '@/router'
import { messageHelper } from '@/helpers'
import _ from 'lodash'

// eslint-disable-next-line no-undef
const display = (error = {}) => {
  let errorKey = 'default'
  if (typeof error === 'string') {
    errorKey = error
  } else {
    let response = error.response || undefined
    if (!response) {
      response = error.data ? error : undefined
    }
    if (response) {
      if (typeof response === 'object' && response.data) {
        const errorDetail = response.data.details
        if (Array.isArray(errorDetail) && errorDetail.length > 0) {
          const msg = errorDetail[0]
          response = {
            data: {
              message: msg ? msg.toLowerCase().split(' ').join('_') : ''
            }
          }
        }
      }
      const messageError = _.get(response, 'data.message') || 'error_default'
      const messageTransforme = messageError.replace(/\s/g, '_').toLowerCase()
      let messageTranslate = router.app.$t(`errors.${messageTransforme}`)

      if (messageTranslate.includes('errors.')) {
        messageTranslate = router.app.$t(`errors.error_default`)
      }

      if (messageTranslate) {
        return messageHelper.error(messageTranslate)
      } else if (messageError) {
        return messageHelper.error(messageError)
      } else if (response.status === 401) {
        errorKey = 'token_expired'
      }
    }
  }

  let message = router.app.$t(`errors.${errorKey}`)

  if (error.message) {
    message = error.message
  } else {
    if (message.includes('errors.')) {
      message = router.app.$t(`errors.default`)
    }
  }

  messageHelper.error(message)
}

export const errorResponseHelper = {
  display
}
